import { useContext, useState } from 'react';
import { Button as DSButton } from '@benefitflow/designsystem';
import { SavedSearchesDialog } from './components/SavedSearchesDialog/SavedSearchesDialog';
import { APIContext } from 'new-beginning/services/api/Api.context';
import { Tooltip } from '@material-ui/core';

export const SavedSearchesButton = ({}: {}) => {
	const { savedSearchApi } = useContext(APIContext);
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const { data: savedSearches, loading: savedSearchesLoading } = savedSearchApi.useGetSavedSearches({
		refetchOnMount: true,
		refetchOnWindowFocus: true,
	});

	return (
		<>
			{savedSearches?.length === 0 ? (
				<Tooltip title="To save a search, apply your filters, then click on “Save Search”." placement="bottom" arrow>
					<span>
						<DSButton testId="saved-searches-button" variant="outlined" size="md" onClick={() => setIsDialogOpen(true)} disabled={savedSearches?.length === 0}>
							Saved Searches
						</DSButton>
					</span>
				</Tooltip>
			) : (
				<DSButton testId="saved-searches-button" variant="outlined" size="md" onClick={() => setIsDialogOpen(true)} disabled={savedSearches?.length === 0}>
					Saved Searches
				</DSButton>
			)}

			<SavedSearchesDialog isSaveSearchDialogOpen={isDialogOpen} setIsSaveSearchDialogOpen={setIsDialogOpen} />
		</>
	);
};
