import { useContext, useMemo } from 'react';

import { useEmployerId } from '../../../../../../../../hooks';
import { useEmployerHistoryTabStore } from '../../../../../History.store';
import { APIContext } from '../../../../../../../../../../../services/api/Api.context';

export const useIsEmpty = (companyType: 'broker' | 'carrier') => {
	const employerId = useEmployerId();
	const {
		policyCategorySelector: { values: categories },
	} = useEmployerHistoryTabStore();
	const {
		employerApi: { useGetPolicyHistory },
	} = useContext(APIContext);
	const { data, isLoading } = useGetPolicyHistory({ companyType, categories, employerId });
	const isEmpty = useMemo(() => !isLoading && !data?.pages?.flatMap(({ data }) => data)?.length, [data, isLoading]);

	return isEmpty;
};
