export enum CrmObjectTypeEnum {
	BROKER_CONTACT = 'BROKER_CONTACT',
	BROKER = 'BROKER',
	BROKER_OFFICE = 'BROKER_OFFICE',
	EMPLOYER_CONTACT = 'EMPLOYER_CONTACT',
	EMPLOYER = 'EMPLOYER',
	SERVICE_PROVIDER = 'SERVICE_PROVIDER',
}

export enum SFObjectTypeEnum {
	CONTACT = 'Contact',
	ACCOUNT = 'Account',
}

export class CrmObjectTypeUtils {
	public static copy(type: CrmObjectTypeEnum) {
		let str = `${CrmObjectTypeEnum[type]}`;
		return CrmObjectTypeEnum[str];
	}

	public static getName(type: CrmObjectTypeEnum) {
		let str = CrmObjectTypeEnum[type];
		if (!str) {
			throw new Error(`Invalid CrmObjectTypeEnum: ${type}`);
		}
		let strWSpaces = `${str}`.replace(/_/g, ' ');
		strWSpaces = strWSpaces.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
			return a.toUpperCase();
		});
		return strWSpaces;
	}
}
