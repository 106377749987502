import { useMemo, useState } from 'react';
import { Box, Chart, Table as DSTable, Typography } from '@benefitflow/designsystem';
import { ChartColors } from '@benefitflow/designsystem/dist/components/Chart/Chart.types';
import { UseTableParams } from '@benefitflow/designsystem/dist/components/Table/hooks/useTable.types';

import { TableData, useTableData } from './hooks/useTableData';
import { ToggleDetailsButton } from '../../../ToggleDetailsButton/ToggleDetailsButton';

export const Table = ({ getPlanColor }: { getPlanColor: (plan: string) => ChartColors }) => {
	const [tableIsVisible, setTableVisibility] = useState(false);
	const { data, isLoading, isFetching, years, fetchNextPage, hasNextPage, count } = useTableData();
	const columns = useMemo<UseTableParams<TableData>['columns']>(
		() => [
			{
				key: 'plan',
				title: 'Benefit Plans',
				headerAlign: 'left',
				cellAlign: 'left',

				render: ({ plan }) => (
					<Box display="flex" alignItems="center" justifyContent="start" gap="sm">
						<Chart.Legend.Marker colorScheme="gradient" color={getPlanColor(plan)} />
						<Typography.Text>{plan}</Typography.Text>
					</Box>
				),
			},
			...years.map((year) => ({
				key: year,
				title: year,
				render: (years) => (
					<Typography.Text size="sm" color="neutral-700" weight="regular">
						{years?.[year]?.['formatted'] ?? '--'}
					</Typography.Text>
				),
			})),
		],
		[years, getPlanColor],
	);
	const props = DSTable.use({
		columns,
		skeleton: () => (
			<DSTable.Skeleton
				numberOfRows={10}
				columns={new Array(6).fill(null).map(() => ({
					height: 'sm',
					width: 'xl2',
				}))}
			/>
		),
		data,
		isEmpty: !isLoading && !data.length,
		isFetching,
		loading: isLoading,
	});

	return (
		<Box mt="xl" display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="lg">
			{tableIsVisible && (
				<Box display="flex" flexDirection="column">
					<DSTable.Title title="Benefit Plans" count={count} isLoading={isLoading} />
					<DSTable {...props} />
				</Box>
			)}
			<ToggleDetailsButton
				fetchNextPage={fetchNextPage}
				hasNextPage={hasNextPage}
				isFetching={isFetching}
				isVisible={tableIsVisible}
				setVisibility={setTableVisibility}
				total={data?.length ?? 0}
			/>
		</Box>
	);
};
