import { Box, Button, Description, Paper } from '@benefitflow/designsystem';

import { EmployersList, Labels } from './components';
import { BrokerOfficeCardProps } from './BrokerOfficeCardV2.types';
import { useGetValueByScreenSizes } from 'new-beginning/hooks/useGetValueByScreenSizes';

export const BrokerOfficeCard = ({
	commissions,
	isExpanded,
	name,
	numberOfClients,
	onSeeClientsCallback,
	onSeeContactsCallback,
	premiums,
	loading,
	cardId,
	location,
}: BrokerOfficeCardProps) => {
	const getValueByScreenSizes = useGetValueByScreenSizes();

	return (
		<Paper>
			<Box
				testId="card-container"
				display="grid"
				id={cardId}
				style={{ gridTemplateColumns: 'repeat(12,1fr)' }}
				gap={getValueByScreenSizes({ sm: 'sm', md: 'sm', lg: undefined })}
			>
				<Box display="flex" gridSpan={getValueByScreenSizes({ lg: 4, md: 6, sm: 12 })} gap="sm">
					<Box display="flex" flexDirection="column" justifyContent="center" alignItems="start" gap="xxs">
						<Labels.Name value={name} loading={loading} />
						<Labels.NumberOfClients value={numberOfClients} loading={loading} />
					</Box>
				</Box>
				<Box
					gridSpan={getValueByScreenSizes({ lg: 4, md: 6, sm: 12 })}
					gap="xs"
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="start"
				>
					{(loading || !!commissions) && <Description icon="badge-percent" label="Commissions" value={commissions} loading={loading} />}
					{(loading || !!premiums) && <Description icon="bills" label="Premiums" value={premiums} loading={loading} />}
				</Box>
				<Box
					gridSpan={getValueByScreenSizes({ lg: 4, md: 12, sm: 12 })}
					display="flex"
					justifyContent={getValueByScreenSizes({ sm: 'center', md: 'center', lg: 'end' })}
					alignItems="center"
					flexWrap={getValueByScreenSizes({ sm: 'wrap', md: 'nowrap', lg: 'nowrap' })}
					gap="sm"
				>
					<Button loading={loading} variant="outlined" onClick={onSeeClientsCallback}>
						See Clients
					</Button>
					<Button loading={loading} onClick={onSeeContactsCallback}>
						See Contacts
					</Button>
				</Box>
			</Box>
			{isExpanded && (
				<Box mt="md">
					<EmployersList location={location} />
				</Box>
			)}
		</Paper>
	);
};
