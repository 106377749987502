import { create } from 'zustand';

import { ServiceProviderStore } from './ServiceProviders.types';
import { StoreUtils } from '../../../../../utilities';
import { ProviderFilters } from 'common.model/src/types/common/search/filters/CompanyFilters';

const FILTERS = ['location' as const];

export const useServiceProvidersStore = create<ServiceProviderStore>((set) => ({
	...StoreUtils.createFilterVisibilityMethods(FILTERS, set),
	filters: { location: [], search: '' },
	clearFilters: () => set({ filters: { location: [], search: '' }, filtersVisibilityMap: { location: false } }),
	setFilters: {
		search: (value) => set((state) => ({ filters: { ...state.filters, search: value } })),
		location: {
			add: (value) =>
				set((state) => ({
					filters: {
						...state.filters,
						location: [...state.filters.location, value],
					},
				})),
			remove: (value) =>
				set((state) => ({
					filters: {
						...state.filters,
						location: state.filters.location.filter((v) => v !== value),
					},
				})),
		},
	},
	setAllFilters: (filters: ProviderFilters) => set({ filters: filters }),
}));
