import { useState } from 'react';
import { Box, Chart, Table as DSTable, Typography } from '@benefitflow/designsystem';

import { useTableData } from './hooks';
import { useGetCompanyColor } from '../../hooks';
import { ToggleDetailsButton } from '../../../ToggleDetailsButton/ToggleDetailsButton';

export const Table = ({
	companyType,
	getCompanyColor,
	title,
}: {
	title: string;
	companyType: 'broker' | 'carrier';
	getCompanyColor: ReturnType<typeof useGetCompanyColor>;
}) => {
	const [tableIsVisible, setTableVisibility] = useState(false);
	const { data, years, hasNextPage, fetchNextPage, count, isFetching, isLoading } = useTableData({ companyType });
	const props = DSTable.use({
		skeleton: () => (
			<DSTable.Skeleton
				numberOfRows={10}
				columns={new Array(6).fill(null).map(() => ({
					height: 'sm',
					width: 'xl2',
				}))}
			/>
		),
		columns: [
			{
				key: 'company',
				title: title,
				headerAlign: 'left',
				render: ({ company }) => (
					<Box display="flex" alignItems="center" justifyContent="start" gap="xs">
						<Chart.Legend.Marker colorScheme="gradient" color={getCompanyColor({ company, companyType })} />
						<Typography.Text textAlign="start">{company}</Typography.Text>
					</Box>
				),
			},
			...years.map((year) => ({
				key: year,
				title: year,
				render: (data) => (
					<Typography.Text size="sm" color="neutral-700" weight="regular">
						{data?.[year]?.['formatted'] ? `$${data[year]['formatted']}` : '--'}
					</Typography.Text>
				),
			})),
		],
		data,
		isFetching,
		loading: isLoading,
	});

	return (
		<Box mt="xl" display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="lg">
			{tableIsVisible && (
				<Box display="flex" flexDirection="column">
					<DSTable.Title isLoading={isLoading} title={title} count={count} />
					<DSTable {...props} />
				</Box>
			)}
			<ToggleDetailsButton
				fetchNextPage={fetchNextPage}
				hasNextPage={hasNextPage}
				isFetching={isFetching}
				isVisible={tableIsVisible}
				setVisibility={setTableVisibility}
				total={data?.length ?? 0}
			/>
		</Box>
	);
};
