import { useState, useEffect } from 'react';
import serviceUrl from 'utils/serviceUrl';
import ITenant from 'types/ITenant';
import axios from 'axios';
import { useBasicApiRequestState, BasicApiRquestState } from './useBasicApiRequestState';

export interface TenantUser {
	id: string;
	tenant_id: string;
	user_type: string;
	name: string;
	email: string;
	credits_used?: number;
	allocation_amount?: number | null;
}

export interface TenantTeam extends ITenant {
	credits_used?: number;
}

export interface TenantUsage {
	tenant: TenantTeam;
	users: TenantUser[];
	totalUsers: number;
}

export interface TenantTeamResponse {
	loading: boolean;
	isFetching: boolean;
	tenantTeamInfo: TenantUsage | null;
	refetchTeamData: (lastUserId?: string) => void;
	hasMoreUsers: boolean;
}

const tenantTeamInfoUrl = `${serviceUrl}/tenant/getTenantTeamInfo`;
export const useTenantTeamInfo = (searchTerm?: string): TenantTeamResponse => {
	const { baseHeaders, loading, setLoading }: BasicApiRquestState = useBasicApiRequestState();
	const [tenantTeamInfo, setTenantTeamInfo] = useState<TenantUsage | null>(null);
	const [hasMoreUsers, setHasMoreUsers] = useState(false);
	const [isFetching, setIsFetching] = useState(false);

	const fetchTeamData = async (lastUserId?: string) => {
		if (lastUserId) {
			setIsFetching(true);
		} else {
			setLoading(true);
		}

		try {
			const response = await axios.get(tenantTeamInfoUrl, {
				headers: { ...baseHeaders },
				params: {
					cursorId: lastUserId,
					...(searchTerm && { search: searchTerm }),
				},
			});
			const data = response?.data?.res?.data;

			if (data) {
				if (lastUserId && tenantTeamInfo) {
					setTenantTeamInfo({
						...tenantTeamInfo,
						users: [...tenantTeamInfo.users, ...data.users],
					});
				} else {
					setTenantTeamInfo(data);
				}
				setHasMoreUsers(data.hasMore || false);
			}
		} catch (error) {
			console.error('Error fetching team data:', error);
		} finally {
			setIsFetching(false);
			setLoading(false);
		}
	};

	const refetchTeamData = (lastUserId?: string) => {
		fetchTeamData(lastUserId);
	};

	useEffect(() => {
		if (baseHeaders?.Authorization) {
			fetchTeamData();
		} else {
			console.warn('Cannot Fetch Team Info without Token');
		}
	}, [baseHeaders?.Authorization, baseHeaders?.bf_login_as_tenant, searchTerm]);

	return {
		loading,
		isFetching,
		tenantTeamInfo,
		refetchTeamData,
		hasMoreUsers,
	};
};
