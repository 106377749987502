import { useContext, useMemo } from 'react';

import { useEmployerId } from '../../../../../../../../hooks';
import { APIContext } from '../../../../../../../../../../../services/api/Api.context';
import { FormattingUtils } from '../../../../../../../../../../../../ui-components';

export type TableData = { [year: string]: Record<'raw' | 'formatted', string | number> } & { plan: string };

export const useTableData = () => {
	const employerId = useEmployerId();
	const {
		employerApi: { useGetEmployeeHistory },
	} = useContext(APIContext);
	const { data, isLoading, isFetching, fetchNextPage, hasNextPage } = useGetEmployeeHistory(
		{ employerId, perPage: 10 },
		{
			getNextPageParam: (lastPage) => lastPage?.next,
		},
	);
	const employeeHistory = useMemo<TableData[]>(
		() =>
			data?.pages
				?.flatMap((page) => page.data)
				.map((row) =>
					Object.entries(row).reduce((acc, [key, value]) => {
						if (key === 'plan') return { ...acc, plan: value } as TableData;
						acc[key] = { raw: value, formatted: FormattingUtils.formatNumberAbbrev(value) };

						return acc;
					}, {} as TableData),
				) ?? [],
		[data],
	);
	const years = useMemo(() => {
		const yearsMap = new Set<string>();
		employeeHistory?.map((data) => {
			Object.keys(data).forEach((year) => {
				if (year != 'plan') yearsMap.add(year);
			});
		});

		return Array.from(yearsMap).sort();
	}, [employeeHistory]);
	const count = useMemo(() => data?.pages[0]?.total ?? 0, [data]);

	return { data: employeeHistory ?? [], count, years, isLoading, fetchNextPage, hasNextPage, isFetching };
};
