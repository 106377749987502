import { Breakdowns } from 'common.model/src/types/common/search/Breakdowns';

import { useBrokerContactsStore } from '../../BrokerContacts.store';
import { LOCATION_INPUT_PLACEHOLDER } from '../../../../../../../app/constants';
import { useBrokerOffices, useContactLocation, useYearsAtCompany } from './hooks';
import { Filters as FiltersComponent } from '../../../../../shared/Filters/Filters';
import { SaveSearchButton } from 'new-beginning/components/shared/SidebarFilters/components/SaveSearchButton/SaveSearchButton';
import { CrmObjectTypeEnum } from 'common.model/src/types/services/integration/fieldMapping/CrmObjectTypeEnum';

export const Filters = ({ breakdowns }: { breakdowns: Breakdowns }) => {
	const { filters, setFilter, filtersVisibilityMap, toggleFilterVisibility, resetFiltersVisibility, resetFilters } = useBrokerContactsStore();
	const contactLocation = useContactLocation();
	const brokerOffices = useBrokerOffices();
	const yearsAtCompany = useYearsAtCompany();

	return (
		<FiltersComponent
			onClear={() => {
				resetFilters();
				resetFiltersVisibility();
			}}
		>
			{/* CONTACT ATTRIBUTES */}
			<FiltersComponent.Section title="Contact Attributes" />
			{/* Company Name */}
			<FiltersComponent.IncludeExcludeFilter
				title="Company Name"
				description="Contacts employed at [Mercer]"
				icon="briefcase"
				isLoading={false}
				isOpen={filtersVisibilityMap.companyName}
				onDelete={({ type, value }) => setFilter.companyName?.[type].remove(value)}
				onSelect={({ type, value }) => setFilter.companyName?.[type].add(value)}
				onToggle={toggleFilterVisibility.companyName}
				options={breakdowns.companyName}
				placeholder="e.g. Mercer"
				selectedOptions={filters.companyName}
				testId=""
			/>
			{/* Contact Location */}
			<FiltersComponent.LocationFilter
				title="Contact Location"
				description="Contacts located in [California]"
				placeholder={LOCATION_INPUT_PLACEHOLDER}
				isLoading={contactLocation.isLoading}
				testId=""
				isOpen={filtersVisibilityMap.contactLocation}
				icon="location"
				onDelete={({ type, value }) => setFilter.contactLocation?.[type].remove({ searchValue: value })}
				onSelect={({ type, value }) => setFilter.contactLocation?.[type].add(value)}
				onSearch={contactLocation.onSearch}
				onToggle={toggleFilterVisibility.contactLocation}
				options={contactLocation.options}
				selectedOptions={filters.contactLocation}
				setRadiusMiles={setFilter.contactLocation.setRadiusMiles}
			/>
			{/* Role */}
			<FiltersComponent.IncludeExcludeFilter
				title="Role"
				description="Contacts with the role of [producer]"
				icon="user-tie"
				isLoading={false}
				isOpen={filtersVisibilityMap.role}
				onDelete={({ type, value }) => setFilter.roles?.[type].remove(value)}
				onSelect={({ type, value }) => setFilter.roles?.[type].add(value)}
				onToggle={toggleFilterVisibility.role}
				options={breakdowns.roles}
				placeholder="e.g. Producer"
				selectedOptions={filters.roles}
				testId=""
			/>
			{/* Job Title */}
			<FiltersComponent.IncludeExcludeFilter
				title="Job Title"
				description="Contacts that have a job title of [consultant]"
				icon="briefcase"
				isLoading={false}
				isOpen={filtersVisibilityMap.jobTitle}
				onDelete={({ type, value }) => setFilter.jobTitles?.[type].remove(value)}
				onSelect={({ type, value }) => setFilter.jobTitles?.[type].add(value)}
				onToggle={toggleFilterVisibility.jobTitle}
				options={breakdowns.jobTitles}
				placeholder="e.g Consultant"
				selectedOptions={filters.jobTitles}
				testId=""
			/>
			{/* Years At Company */}
			<FiltersComponent.RangeFilter
				title={'Years At Company'}
				description={'Contacts that have [<2] years at current company'}
				isLoading={false}
				testId=""
				defaultOption={{ start: '0', end: '21' }}
				onToggle={toggleFilterVisibility.yearsAtCompany}
				isOpen={filtersVisibilityMap.yearsAtCompany}
				options={yearsAtCompany.options}
				onSelect={setFilter.yearsAtCompany}
				selectedRange={{ start: filters.yearsAtCompany[0], end: filters.yearsAtCompany[1] }}
				icon="calendar"
			/>
			{/* License Type */}
			<FiltersComponent.IncludeExcludeFilter
				title="License Type"
				description="Contacts that are licensed to sell [Health] insurance"
				icon="shield-check"
				isLoading={false}
				isOpen={filtersVisibilityMap.licenseTypes}
				onDelete={({ type, value }) => setFilter.licenseTypes?.[type].remove(value)}
				onSelect={({ type, value }) => setFilter.licenseTypes?.[type].add(value)}
				onToggle={toggleFilterVisibility.licenseTypes}
				options={breakdowns.licenseTypes}
				placeholder="e.g. Health"
				selectedOptions={filters.licenseTypes}
				testId=""
			/>
			{/* Carrier Appointments */}
			<FiltersComponent.StringSearchFilter
				title="Carrier Appointments"
				description="Contacts that are licensed to sell [Aetna] products"
				placeholder="e.g. Aetna"
				icon="building-shield"
				isLoading={false}
				isOpen={filtersVisibilityMap.carrierAppointments}
				onToggle={toggleFilterVisibility.carrierAppointments}
				onDelete={setFilter.carrierAppointments.include.remove}
				onSelect={setFilter.carrierAppointments.include.add}
				testId=""
				value={filters?.carrierAppointments?.include?.[0]}
			/>
			{/* BROKER ATTRIBUTES */}
			<FiltersComponent.Section title="Broker Attributes" />
			{/* Office Location */}
			<FiltersComponent.LocationFilter
				title="Office Location"
				description="Office locations in [California]"
				placeholder={LOCATION_INPUT_PLACEHOLDER}
				isLoading={brokerOffices.isLoading}
				icon="location"
				isOpen={filtersVisibilityMap.brokerOfficeLocations}
				onDelete={({ type, value }) => setFilter.brokerOfficeLocations?.[type].remove({ searchValue: value })}
				onSearch={brokerOffices.onSearch}
				onSelect={({ type, value }) => setFilter.brokerOfficeLocations?.[type].add(value)}
				onToggle={toggleFilterVisibility.brokerOfficeLocations}
				options={brokerOffices.options}
				selectedOptions={filters.brokerOfficeLocations}
				setRadiusMiles={setFilter.brokerOfficeLocations.setRadiusMiles}
				testId=""
			/>
			{/* Broker Size */}
			<FiltersComponent.IncludeExcludeFilter
				title="Broker Size"
				description="Broker with [national] presence"
				icon="briefcase"
				isLoading={brokerOffices.isLoading}
				isOpen={filtersVisibilityMap.brokerSize}
				onDelete={({ type, value }) => setFilter.brokerSize?.[type].remove(value)}
				onSelect={({ type, value }) => setFilter.brokerSize?.[type].add(value)}
				onToggle={toggleFilterVisibility.brokerSize}
				options={breakdowns.brokerSize}
				placeholder="e.g. National or Regional"
				selectedOptions={filters.brokerSize}
				testId=""
			/>
			<SaveSearchButton filters={filters} crmObjectTypeEnum={CrmObjectTypeEnum.BROKER_CONTACT} />
		</FiltersComponent>
	);
};
