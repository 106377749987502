import { ReactNode, useContext, useMemo } from 'react';
import { APIContext } from './Api.context';
import { WithAuthenticatedContext } from '../../context/AuthenticatedContext';
import { DataExportApi } from './clients/DataExportApi';
import { BrokerApi } from './clients/BrokerApi';
import { EmployerApi } from './clients/EmployerApi';
import { BrokerContactApi } from './clients/BrokerContactApi';
import { ProviderApi } from './clients/ProviderApi';
import { TenantApi } from './clients/TenantApi';
import { UserApi } from './clients/UserApi';
import { ListApi } from './clients/ListsApi';
import { ExportApiHTTP } from './clients/ExportApi.http';
import { LoggedInUserContext } from 'new-beginning/context/LoggedInUser';
import { SavedSearchApi } from './clients/SavedSearchApi';

export const APIProvider = ({ children }: { children: ReactNode }) => {
	const { httpClient } = useContext(WithAuthenticatedContext);
	const { authUser } = useContext(LoggedInUserContext);

	const value = useMemo(
		() => ({
			brokerApi: new BrokerApi(httpClient, authUser?.tenantIdOverride),
			brokerContactApi: new BrokerContactApi(httpClient, authUser?.tenantIdOverride),
			dataExportApi: new DataExportApi(httpClient, authUser?.tenantIdOverride),
			employerApi: new EmployerApi(httpClient, authUser?.tenantIdOverride),
			listsApi: new ListApi(httpClient, authUser?.tenantIdOverride),
			providerApi: new ProviderApi(httpClient, authUser?.tenantIdOverride),
			tenantApi: new TenantApi(httpClient, authUser?.tenantIdOverride),
			userApi: new UserApi(httpClient, authUser?.tenantIdOverride),
			exportApi: new ExportApiHTTP(httpClient),
			savedSearchApi: new SavedSearchApi(httpClient, authUser?.tenantIdOverride),
		}),
		[httpClient, authUser?.tenantIdOverride],
	);

	return <APIContext.Provider value={value}>{children}</APIContext.Provider>;
};
