import { Location } from './components';
import { useServiceProvidersStore } from '../../ServiceProviders.store';
import { Filters as FiltersComponent } from '../../../../../../shared/Filters/Filters';
import { SaveSearchButton } from 'new-beginning/components/shared/SidebarFilters/components/SaveSearchButton/SaveSearchButton';
import { CrmObjectTypeEnum } from 'common.model/src/types/services/integration/fieldMapping/CrmObjectTypeEnum';

export const Filters = () => {
	const { clearFilters, filters } = useServiceProvidersStore();

	return (
		<FiltersComponent onClear={clearFilters}>
			<FiltersComponent.Section title="Service Provider Attributes" />
			<Location />
			<SaveSearchButton filters={filters} crmObjectTypeEnum={CrmObjectTypeEnum.SERVICE_PROVIDER} />
		</FiltersComponent>
	);
};
