import { useContext, useMemo } from 'react';

import { useEmployerId } from '../../../../../../../../hooks';
import { useEmployerHistoryTabStore } from '../../../../../History.store';
import { FormattingUtils } from '../../../../../../../../../../../../ui-components';
import { APIContext } from '../../../../../../../../../../../services/api/Api.context';

export const useTableData = ({ companyType }: { companyType: 'broker' | 'carrier' }) => {
	const {
		policyCategorySelector: { values: categories },
	} = useEmployerHistoryTabStore();
	const employerId = useEmployerId();
	const {
		employerApi: { useGetPolicyHistory, useGetFillingYears },
	} = useContext(APIContext);
	const {
		data: policyHistory,
		isLoading,
		fetchNextPage,
		isFetching,
		hasNextPage,
	} = useGetPolicyHistory(
		{
			categories,
			companyType,
			employerId,
			perPage: 10,
		},
		{
			getNextPageParam: (lastPage) => lastPage?.next,
		},
	);
	const count = useMemo(() => policyHistory?.pages?.[0]?.total ?? 0, [policyHistory]);
	const data = useMemo(
		() =>
			policyHistory?.pages?.flatMap(({ data }) => {
				return data.map((data) => {
					return Object.entries(data).reduce((acc, [key, value]) => {
						if (key === 'company') return { ...acc, [key]: value };
						return {
							...acc,
							[key]: {
								raw: value,
								formatted: FormattingUtils.formatNumberAbbrev(value),
							},
						};
					}, {});
				});
			}) ?? [],
		[policyHistory],
	);
	const { data: fillingYears } = useGetFillingYears({ employerId });

	return { data: data ?? [], years: fillingYears?.years ?? [], count, isLoading, fetchNextPage, isFetching, hasNextPage };
};
