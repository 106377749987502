import { useEmployersStore } from '../../Employers.store';
import { Breakdowns } from 'common.model/src/types/common/search/Breakdowns';
import { LOCATION_INPUT_PLACEHOLDER } from '../../../../../../../app/constants';
import { Filters as FiltersComponent } from '../../../../../shared/Filters/Filters';
import { usePrimaryBrokerOfficeLocations, useEmployeeCount, useEmployerLocation, useRenewalDate } from './hooks';
import { SaveSearchButton } from 'new-beginning/components/shared/SidebarFilters/components/SaveSearchButton/SaveSearchButton';
import { CrmObjectTypeEnum } from 'common.model/src/types/services/integration/fieldMapping/CrmObjectTypeEnum';

export const Filters = ({ breakdowns, isLoading }: { isLoading: boolean; breakdowns?: Breakdowns }) => {
	const { filters, setFilters, filtersVisibilityMap, toggleFilterVisibility, clearFilters } = useEmployersStore();
	const employeeCount = useEmployeeCount();
	const employerLocation = useEmployerLocation();
	const primaryBrokerOfficeLocations = usePrimaryBrokerOfficeLocations();
	const renewalDate = useRenewalDate();

	return (
		<FiltersComponent onClear={clearFilters}>
			<FiltersComponent.Section title="Employer Attributes" />
			{/* Company Name/Website Match */}
			<FiltersComponent.URLStringSearchFilter
				title="Company Name"
				description="Employers named [Walmart]"
				placeholder="e.g. Walmart"
				icon="business"
				isLoading={false}
				testId=""
				isOpen={filtersVisibilityMap.employerNameSearch}
				onToggle={toggleFilterVisibility.employerNameSearch}
				onDeleteStringSearch={() => setFilters.employerNameSearch('')}
				onSelectStringSearch={(input) => setFilters.employerNameSearch(input)}
				stringSearchValue={filters.employerNameSearch}
				masterIds={filters.employerMasterId}
				onSetMasterIds={setFilters.employerMasterId}
				onClearMasterIds={() => setFilters.employerMasterId([])}
			/>
			{/* Employee Count */}
			<FiltersComponent.CustomRangeFilter
				title="Employee Count"
				description="Employers with [0 - 10k] employees"
				defaultOption={{ start: '0', end: '3000000' }}
				icon="users"
				isOpen={filtersVisibilityMap.participantRanges}
				isLoading={isLoading}
				onSelect={setFilters.participantRanges}
				onToggle={toggleFilterVisibility.participantRanges}
				options={employeeCount.options}
				selectedRange={filters.participantRanges}
				testId=""
			/>
			{/* HQ Location */}
			<FiltersComponent.LocationFilter
				title="HQ Location"
				description="Employers located in [California]"
				icon="location"
				isLoading={employerLocation.isLoading}
				isOpen={filtersVisibilityMap.employerHQLocations}
				options={employerLocation.options}
				onDelete={({ type, value }) => setFilters.employerHQLocations?.[type].remove({ searchValue: value })}
				onSelect={({ type, value }) => setFilters.employerHQLocations?.[type].add(value)}
				onToggle={toggleFilterVisibility.employerHQLocations}
				onSearch={employerLocation.onSearch}
				placeholder={LOCATION_INPUT_PLACEHOLDER}
				selectedOptions={filters.employerHQLocations}
				setRadiusMiles={setFilters.employerHQLocations?.setRadiusMiles}
				testId=""
			/>
			{/* Industry */}
			<FiltersComponent.IncludeExcludeFilter
				title="Industry"
				description="Employers in the [Utilities] industry"
				icon="briefcase"
				isOpen={filtersVisibilityMap.industries}
				isLoading={isLoading}
				onSelect={({ type, value }) => setFilters.industries?.[type].add(value)}
				onDelete={({ type, value }) => setFilters.industries?.[type].remove(value)}
				placeholder="e.g Information or Utilities"
				onToggle={toggleFilterVisibility.industries}
				options={breakdowns?.industries}
				selectedOptions={filters.industries}
				testId=""
			/>
			{/* Employer Type */}
			<FiltersComponent.IncludeExcludeFilter
				title="Employer Type"
				description="Employers that are [multiemployer]"
				placeholder="e.g. Single-Employer or Multiemployer"
				icon="sitemap"
				isLoading={isLoading}
				isOpen={filtersVisibilityMap.entityTypes}
				onDelete={({ type, value }) => setFilters.entityTypes?.[type].remove(value)}
				onSelect={({ type, value }) => setFilters.entityTypes?.[type].add(value)}
				onToggle={toggleFilterVisibility.entityTypes}
				options={breakdowns?.entityTypes}
				selectedOptions={filters.entityTypes}
				testId=""
			/>
			{/* Funding */}
			<FiltersComponent.IncludeExcludeFilter
				title="Funding"
				description="Employers that are [self-funded]"
				placeholder="e.g. Fully Insured or Self-Funded"
				icon="bills"
				isLoading={isLoading}
				isOpen={filtersVisibilityMap.planTypes}
				onDelete={({ type, value }) => setFilters.planTypes?.[type].remove(value)}
				onSelect={({ type, value }) => setFilters.planTypes?.[type].add(value)}
				onToggle={toggleFilterVisibility.planTypes}
				options={breakdowns?.planTypes}
				selectedOptions={filters.planTypes}
				testId=""
			/>
			{/* Insurance Products */}
			<FiltersComponent.IncludeExcludeFilter
				title="Insurance Products"
				description="Employers with [dental] products"
				placeholder="e.g. Dental or Vision"
				icon="shield"
				isLoading={isLoading}
				isOpen={filtersVisibilityMap.lineOfBusiness}
				onDelete={({ type, value }) => setFilters.lineOfBusiness?.[type].remove(value)}
				onSelect={({ type, value }) => setFilters.lineOfBusiness?.[type].add(value)}
				onToggle={toggleFilterVisibility.lineOfBusiness}
				options={breakdowns?.lineOfBusiness}
				selectedOptions={filters.lineOfBusiness}
				testId=""
			/>
			{/* Carrier */}
			<FiltersComponent.IncludeExcludeFilter
				title="Carrier"
				description="Employers with [Aetna] products"
				placeholder="e.g Aetna or Humana"
				icon="building-shield"
				isLoading={isLoading}
				isOpen={filtersVisibilityMap.carrierName}
				onDelete={({ type, value }) => setFilters.carrierName?.[type].remove(value)}
				onSelect={({ type, value }) => setFilters.carrierName?.[type].add(value)}
				onToggle={toggleFilterVisibility.carrierName}
				options={breakdowns?.carrierName}
				selectedOptions={filters.carrierName}
				testId=""
			/>
			{/* Renewal Date */}
			<FiltersComponent.RangeFilter
				title={'Renewal Date'}
				defaultOption={{ start: '1', end: '12' }}
				testId=""
				description={'Employers with [July] renewals'}
				isLoading={false}
				isOpen={filtersVisibilityMap.renewalDatesRanges}
				onToggle={toggleFilterVisibility.renewalDatesRanges}
				options={renewalDate.options}
				onSelect={setFilters.renewalDatesRanges}
				selectedRange={filters.renewalDatesRanges}
				icon="calendar"
			/>

			{/* PEO */}
			<FiltersComponent.IncludeExcludeFilter
				title="PEO"
				description="Clients with [ADP] PEO"
				placeholder="e.g. ADP or TriNet"
				icon="peo"
				isLoading={isLoading}
				allOptionsLabel="Any PEO"
				isOpen={filtersVisibilityMap.employerPEOName}
				onDelete={({ type, value }) => setFilters.employerPEOName?.[type].remove(value)}
				onSelect={({ type, value }) => setFilters.employerPEOName?.[type].add(value)}
				options={breakdowns?.employerPEOName}
				onToggle={toggleFilterVisibility.employerPEOName}
				selectedOptions={filters.employerPEOName}
				testId=""
			/>
			{/* Accountant Firm */}
			<FiltersComponent.IncludeExcludeFilter
				title="Accounting Firm"
				description="Employers that use [KPMG] for accounting"
				placeholder="e.g. KPMG or Deloitte"
				icon="calculator"
				isLoading={isLoading}
				allOptionsLabel="Any Accounting Firm"
				isOpen={filtersVisibilityMap.employerAccountantFirmName}
				onDelete={({ type, value }) => setFilters.employerAccountantFirmName?.[type].remove(value)}
				onSelect={({ type, value }) => setFilters.employerAccountantFirmName?.[type].add(value)}
				options={breakdowns?.employerAccountantFirmName}
				onToggle={toggleFilterVisibility.employerAccountantFirmName}
				selectedOptions={filters.employerAccountantFirmName}
				testId=""
			/>
			<FiltersComponent.Section title="Broker Attributes" />
			{/* Broker Office Location */}
			<FiltersComponent.LocationFilter
				title="Broker Office Location"
				description="Broker Office located in [California]"
				icon="location"
				isLoading={primaryBrokerOfficeLocations.isLoading}
				isOpen={filtersVisibilityMap.primaryBrokerOfficeLocations}
				onDelete={({ type, value }) => setFilters.primaryBrokerOfficeLocations?.[type].remove({ searchValue: value })}
				onSelect={({ type, value }) => setFilters.primaryBrokerOfficeLocations?.[type].add(value)}
				onSearch={primaryBrokerOfficeLocations.onSearch}
				onToggle={toggleFilterVisibility.primaryBrokerOfficeLocations}
				options={primaryBrokerOfficeLocations.options}
				placeholder={LOCATION_INPUT_PLACEHOLDER}
				selectedOptions={filters.primaryBrokerOfficeLocations}
				setRadiusMiles={setFilters.primaryBrokerOfficeLocations?.setRadiusMiles}
				testId=""
			/>
			{/* Broker Size */}
			<FiltersComponent.IncludeExcludeFilter
				title="Broker Size"
				description="Brokers with [national] presence"
				icon="bars"
				isLoading={isLoading}
				isOpen={filtersVisibilityMap.brokerSize}
				onDelete={({ type, value }) => setFilters.brokerSize?.[type].remove(value)}
				onSelect={({ type, value }) => setFilters.brokerSize?.[type].add(value)}
				options={breakdowns?.brokerSize}
				onToggle={toggleFilterVisibility.brokerSize}
				selectedOptions={filters.brokerSize}
				testId=""
				placeholder="e.g. National or Regional"
			/>
			{/* Broker Name */}
			<FiltersComponent.IncludeExcludeFilter
				title="Broker Name"
				description="Companies that work with [Mercer]"
				icon="briefcase"
				isLoading={isLoading}
				isOpen={filtersVisibilityMap.brokerName}
				onDelete={({ type, value }) => setFilters.brokerName?.[type].remove(value)}
				onSelect={({ type, value }) => setFilters.brokerName?.[type].add(value)}
				options={breakdowns?.brokerName}
				onToggle={toggleFilterVisibility.brokerName}
				selectedOptions={filters.brokerName}
				testId=""
				placeholder="e.g. Mercer"
			/>
			<SaveSearchButton filters={filters} crmObjectTypeEnum={CrmObjectTypeEnum.EMPLOYER} />
		</FiltersComponent>
	);
};
