import { Card, Table as DSTable } from '@benefitflow/designsystem';

import { useIsEmpty } from './hooks';
import { Graph, Table } from './components';
import { useGetPlanColor } from './hooks/useGetPlanColor';

export const EmployeeHistory = () => {
	const getPlanColor = useGetPlanColor();
	const isEmpty = useIsEmpty();

	return (
		<Card title="Employee Count">
			{isEmpty ? (
				<DSTable.Empty style={{ height: '16rem' }} showBorder icon="duotone-building-magnifying-glass" label="Data not reported in filings" />
			) : (
				<>
					<Graph getPlanColor={getPlanColor} />
					<Table getPlanColor={getPlanColor} />
				</>
			)}
		</Card>
	);
};
