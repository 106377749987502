import { useMemo } from 'react';
import { ChartColors } from '@benefitflow/designsystem/dist/components/Chart/Chart.types';
import { Box, Chart, Icon, Table, TextButton, Tooltip, Typography } from '@benefitflow/designsystem';
import { TableTitle } from '../../../TableTitle';
import { DetailedInfoTooltip } from '../../../DetailedInfoTooltip';
import { useEmployerProfileBenefitsTabStore } from '../../../../Benefits.store';
import RouterUtils from 'app/RouterUtils';

export interface ServiceProviderDTO {
	name: string;
	services: string[];
	value: {
		raw: number;
		formatted: string;
	};
	providerId: string;
}

export const ServiceProvidersTable = ({
	title,
	data,
	isLoading,
	total,
	hasNextPage,
	fetchNextPage,
	isFetching,
	count,
}: {
	title: string;
	isFetching: boolean;
	hasNextPage: boolean;
	fetchNextPage: () => void;
	isLoading: boolean;
	total: number;
	count: number;
	data: ServiceProviderDTO[];
}) => {
	const { year } = useEmployerProfileBenefitsTabStore();
	const isEmpty = useMemo(() => !isLoading && !data?.length, [isLoading, data]);
	const empty = useMemo(
		() => <Table.Empty style={{ height: '20rem' }} icon="duotone-building-magnifying-glass" label={`Data not reported in ${year.value} filings`} />,
		[year.value],
	);
	const props = Table.use<ServiceProviderDTO>({
		data,
		isEmpty,
		empty,
		columns: [
			{
				cellAlign: 'left',
				headerAlign: 'left',
				key: 'name',
				title: 'Service Provider',
				render: ({ name, providerId }) => (
					<TextButton
						onClick={() => {
							window.open(RouterUtils.getProviderPath(providerId, name), '_blank');
						}}
					>
						{name}
					</TextButton>
				),
			},
			{
				key: 'services',
				title: 'Services',
				borderRight: true,
				render: ({ services }) => (
					<Tooltip content={<DetailedInfoTooltip title="Services" values={services.map((title) => ({ title }))} />}>
						{(ref) => (
							<Box containerRef={ref}>
								<Icon size="md" name="circle-info" color="neutral-500" />
							</Box>
						)}
					</Tooltip>
				),
			},
			{
				key: 'value',
				title: 'Fees Paid',
				cellAlign: 'right',
				headerAlign: 'right',
				render: ({ value, name }) => (
					<Box display="flex">
						<Tooltip content={<DetailedInfoTooltip title={name} values={[{ title: 'Fees', value: value.formatted }]} />}>
							{(ref) => (
								<Box width="full" containerRef={ref}>
									<Chart.Fill height="sm" total={total} parts={[{ value: value.raw, data: {}, color: ChartColors.LIGHT_BLUE }]} fill></Chart.Fill>
								</Box>
							)}
						</Tooltip>

						<Box width="xl4">
							<Typography.Text size="sm" whiteSpace="nowrap">
								{value.formatted}
							</Typography.Text>
						</Box>
					</Box>
				),
			},
		],
		loading: isLoading,
		isFetching,
		skeleton: () => <Table.Skeleton numberOfRows={5} columns={[{ height: 'sm', width: 'xl4' }]} />,
	});

	return (
		<Box my="md">
			<TableTitle isLoading={isLoading} title={title} count={count} />
			<Table {...props} />
			{hasNextPage && !isFetching && (
				<Box mt="sm" display="flex" alignItems="center" justifyContent="center" width="full">
					<Box>
						<TextButton whiteSpace="nowrap" onClick={fetchNextPage}>
							Load More
						</TextButton>
					</Box>
				</Box>
			)}
		</Box>
	);
};
