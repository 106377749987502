import { ReactElement, useState, useEffect, useMemo, useCallback } from 'react';
import classnames from 'classnames';
import { Snackbar, SnackbarContent, Box } from '@material-ui/core';
import { Input } from '@benefitflow/designsystem';
import { debounce } from 'lodash-es';
import { useTenantTeamInfo, TenantTeamResponse, TenantUser } from 'new-beginning/hooks/useTenantTeamInfo';
import { TeamHeader } from './TeamHeader';
import { UserTable } from './UserTable';
import { ConfirmDeleteUserModal } from './ConfirmDeleteUserModal';
import { InviteUserModalProps } from './InviteUserModal';
import { EditUserAttributesModal } from './EditUserAttributesModal';

// TODO: This Route/Page needs to be accessible by Tenant Admins beyond BF
// TODO: This component needs to listen to the current Tenant for BF Admin Users
// TODO: This component needs to Validate/block certain operations (i.e delete only for admin, can't delete yourself.)

const DELAY = 300;

export const TeamView = (): ReactElement => {
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [debouncedSearchInput, setDebouncedSearchInput] = useState(searchTerm);
	const { loading: teamDataLoading, isFetching, tenantTeamInfo, refetchTeamData, hasMoreUsers }: TenantTeamResponse = useTenantTeamInfo(searchTerm);
	const [showDataLoader, setShowLoader] = useState<boolean>(false);

	// State for adding, editing, and deleting Users
	const [showInviteUser, setShowInviteUser] = useState<boolean>(false);
	const [userToEdit, setUserToEdit] = useState<TenantUser | null>(null);
	const [userToDelete, stageUserToDelete] = useState<TenantUser | null>(null);

	useEffect(() => {
		const debounced = debounce(() => setSearchTerm(debouncedSearchInput), DELAY);
		debounced();
		return debounced.cancel;
	}, [debouncedSearchInput]);

	useEffect(() => {
		if (!searchTerm) setDebouncedSearchInput('');
	}, [searchTerm]);

	const refetchAfterSave = () => {
		setShowLoader(true);
		refetchTeamData();
	};

	const loadMoreUsers = useCallback(() => {
		const lastUser = tenantTeamInfo?.users?.[tenantTeamInfo.users.length - 1];
		if (lastUser) {
			refetchTeamData(lastUser.id);
		}
	}, [tenantTeamInfo?.users, refetchTeamData]);

	// TODO: Handle display of Error states for all Hooks
	return (
		<>
			<div className={classnames('page-container', 'team-page-container')}>
				<div className={classnames('mx-auto')} style={{ maxWidth: '1200px' }}>
					<div className={classnames('h-75', 'w-100', 'mx-0')}>
						<TeamHeader showAddUser={() => setShowInviteUser(true)} tenantTeam={tenantTeamInfo} />

						<Box my={4}>
							<Input
								startIcon="search"
								placeholder="Search team members..."
								onChange={(e) => setDebouncedSearchInput(e.target.value)}
								value={debouncedSearchInput}
							/>

							<UserTable
								tenantUsers={tenantTeamInfo?.users}
								setUserToEdit={setUserToEdit}
								setUserToDelete={stageUserToDelete}
								loading={teamDataLoading}
								isFetching={isFetching}
								loadMoreUsers={loadMoreUsers}
								hasMoreUsers={hasMoreUsers}
								totalUsers={tenantTeamInfo?.totalUsers}
							/>
						</Box>
					</div>
				</div>
			</div>
			<InviteUserModalProps isVisible={showInviteUser} cancelInvite={() => setShowInviteUser(false)} refetchData={refetchAfterSave} />
			<EditUserAttributesModal
				isVisible={userToEdit !== null}
				userToUpdate={userToEdit}
				cancelEdits={() => setUserToEdit(null)}
				refetchData={refetchAfterSave}
			/>
			<ConfirmDeleteUserModal
				isVisible={userToDelete !== null}
				userToDelete={userToDelete}
				cancelDeletion={() => stageUserToDelete(null)}
				refetchData={refetchAfterSave}
			/>
			<Snackbar open={showDataLoader} autoHideDuration={850} onClose={() => setShowLoader(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
				<SnackbarContent
					message={
						<div className={classnames('d-flex', 'align-items-center')}>
							<div className="text-primary">
								<span className={classnames('spinner-border', 'spinner-border-md', 'mx-2')} />
							</div>
							<div className="mx-3">Refreshing Team Data</div>
						</div>
					}
				/>
			</Snackbar>
		</>
	);
};
