import { useContext, useMemo } from 'react';
import { ChartColors } from '@benefitflow/designsystem/dist/components/Chart/Chart.types';

import { useEmployerId } from '../../../../../../../../hooks';
import { APIContext } from '../../../../../../../../../../../services/api/Api.context';
import { ChartLine } from '@benefitflow/designsystem/dist/components/Chart/components/Line/Line.types';
import { FormattingUtils } from '../../../../../../../../../../../../ui-components';

export const useGraphData = ({ getPlanColor }: { getPlanColor: (plan: string) => ChartColors }) => {
	const employerId = useEmployerId();
	const {
		employerApi: { useGetEmployeeHistory, useGetEmployeeHistoryChartData },
	} = useContext(APIContext);
	const { data, isLoading: isLoadingData } = useGetEmployeeHistoryChartData(
		{ employerId },
		{ select: (data) => data?.map(({ year, ...data }) => ({ name: year, ...data })) ?? [] },
	);
	const { data: employeeHistory, isLoading: isLoadingOrderedPlans } = useGetEmployeeHistory({
		employerId,
		perPage: 10,
	});
	const orderedPlans = useMemo(() => employeeHistory?.pages?.flatMap((page) => page.data) ?? [], [employeeHistory]);
	const lines = useMemo<ChartLine[]>(
		() =>
			orderedPlans
				?.map(({ plan }, index) => ({
					name: plan,
					color: getPlanColor(plan),
					formatValue: (value) => String(FormattingUtils.formatNumberAbbrev(value)),
					showLabel: index === 0,
				}))
				?.slice(0, 5) ?? [],
		[getPlanColor, orderedPlans],
	);
	const legend = useMemo(
		() =>
			orderedPlans
				?.map(({ plan }) => ({
					label: plan,
					color: getPlanColor(plan),
				}))
				?.slice(0, 5) ?? [],
		[orderedPlans, getPlanColor],
	);
	const isLoading = useMemo(() => isLoadingData || isLoadingOrderedPlans, [isLoadingData, isLoadingOrderedPlans]);

	return { data, lines, legend, isLoading };
};
