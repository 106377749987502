import { useContext, useMemo } from 'react';
import { Button } from '@benefitflow/designsystem';

import { useOnClick } from './hooks';
import { ListActionUtils } from '../../../../../utilities';
import { ContactListHeaderContext } from '../../ContactListHeader.context';

export const ExportButton = () => {
	const {
		store: { checkMode, checkedMap },
	} = useContext(ContactListHeaderContext);
	const { onClick, loading } = useOnClick();
	const disabled = useMemo(() => ListActionUtils.isGenericActionDisabled({ checkedMap, checkMode }), [checkedMap, checkMode]);

	return (
		<Button disabled={disabled} variant="outlined" icon="download" onClick={onClick} fetching={loading}>
			Export
		</Button>
	);
};
