import { Box, Button, Tabs } from '@benefitflow/designsystem';

import { TabsEnum } from './Search.types';
import { Brokers } from './Brokers/Brokers';
import { ServiceProviders } from './components';
import { useSearchStore } from './Search.store';
import { Employers } from './Employers/Employers';
import { BrokerContacts } from './BrokerContacts/BrokerContacts';
import { EmployerContacts } from './EmployerContacts/EmployerContacts';
import { SavedSearchesButton } from './SavedSearchesButton/SavedSearchesButton';

export const Search = () => {
	const { tab, setTab } = useSearchStore();

	return (
		<Box display="flex" flexDirection="column" m="sm">
			<Tabs align="start" onChange={(tab) => setTab(tab as TabsEnum)} value={tab}>
				<Tabs.Tab label="Brokers" icon="business" value={TabsEnum.BROKERS}>
					<Brokers />
				</Tabs.Tab>
				<Tabs.Tab label="Broker Contacts" icon="users" value={TabsEnum.BROKER_CONTACTS}>
					<BrokerContacts />
				</Tabs.Tab>
				<Tabs.Divider />
				<Tabs.Tab label="Employers" icon="business" value={TabsEnum.EMPLOYERS}>
					<Employers />
				</Tabs.Tab>
				<Tabs.Tab label="Employer Contacts" icon="users" value={TabsEnum.EMPLOYER_CONTACTS}>
					<EmployerContacts />
				</Tabs.Tab>
				<Tabs.Divider />
				<Tabs.Tab label="Service Providers" icon="business" value={TabsEnum.SERVICE_PROVIDERS}>
					<ServiceProviders />
				</Tabs.Tab>
				<div style={{ position: 'absolute', right: '25px' }}>
					<SavedSearchesButton />
				</div>
			</Tabs>
		</Box>
	);
};
