import { useMemo } from 'react';

import { ButtonMode } from '../../ToggleDetailsButton.types';

export const useMode = ({ isVisible, hasNextPage, total }: { isVisible: boolean; hasNextPage: boolean; total: number }): ButtonMode => {
	const mode = useMemo(() => {
		if (!isVisible) return 'show-details';
		if (hasNextPage && total <= 20) return 'load-more';
		return 'hide-details';
	}, [isVisible, hasNextPage, total]);

	return mode;
};
