import { ChartColors } from '@benefitflow/designsystem/dist/components/Chart/Chart.types';

export enum Tabs {
	BENEFITS = 'benefits',
	HISTORY = 'history',
	CONTACTS = 'contacts',
	EMPLOYEE_SENTIMENT = 'employeeSentiment',
}

export interface EmployerProfileStore {
	currentTab?: Tabs;
	setCurrentTab: (tab: Tabs) => void;
	setCurrentTabTo: Record<Tabs, () => void>;
}

export const CHART_COLORS = [ChartColors.LIGHT_BLUE, ChartColors.GREEN, ChartColors.LIME, ChartColors.YELLOW, ChartColors.ORANGE];
