import { useContext, useMemo } from 'react';
import { ChartLegendProps } from '@benefitflow/designsystem/dist/components/Chart/components/Legend/Legend.types';

import { useGetCompanyColor } from '../../../hooks';
import { useEmployerId } from '../../../../../../../../hooks';
import { useEmployerHistoryTabStore } from '../../../../../History.store';
import { APIContext } from '../../../../../../../../../../../services/api/Api.context';
import { ChartColors } from '@benefitflow/designsystem/dist/components/Chart/Chart.types';

export const useLegend = ({ getCompanyColor, companyType }: { companyType: 'broker' | 'carrier'; getCompanyColor: ReturnType<typeof useGetCompanyColor> }) => {
	const employerId = useEmployerId();
	const {
		policyCategorySelector: { values: categories },
	} = useEmployerHistoryTabStore();
	const {
		employerApi: { useGetPolicyHistory },
	} = useContext(APIContext);
	const { data: policyHistory, isLoading } = useGetPolicyHistory({
		companyType,
		employerId,
		perPage: 10,
		categories,
	});
	const legend = useMemo<ChartLegendProps['data']>(() => {
		if (!policyHistory?.pages?.length) return [];
		if (policyHistory.pages[0].total <= 5)
			return policyHistory.pages.flatMap(({ data }) => data).map(({ company }) => ({ color: getCompanyColor({ company, companyType }), label: company }));
		return [
			...policyHistory.pages
				.flatMap(({ data }) => data)
				.slice(0, 4)
				.map(({ company }) => ({ color: getCompanyColor({ company, companyType }), label: company })),
			...(policyHistory.pages[0].total > 5
				? [{ color: ChartColors.ORANGE, label: `${policyHistory.pages[0].total - 4} Other ${companyType === 'broker' ? 'Brokers' : 'Carriers'}` }]
				: []),
		];
	}, [policyHistory, companyType, getCompanyColor]);

	return { legend, isLoading };
};
