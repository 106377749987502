import { Box, Tabs } from '@benefitflow/designsystem';

import { useClearState } from './hooks';
import { BrokerProfileTabs } from './BrokerProfile.types';
import { useBrokerProfileStore } from './BrokerProfile.store';
import { Clients, Contacts, Header, Summary } from './components';

export const BrokerProfile = () => {
	const { setTab, tab } = useBrokerProfileStore();
	useClearState();

	return (
		<Box display="flex" flexDirection="column" width="full">
			<Header />
			<Summary />
			<Box m="sm">
				<Tabs align="start" value={tab} onChange={setTab}>
					<Tabs.Tab icon="business" label="Clients" value={BrokerProfileTabs.CLIENTS}>
						<Clients />
					</Tabs.Tab>
					<Tabs.Tab icon="users" label="Contacts" value={BrokerProfileTabs.CONTACTS}>
						<Contacts />
					</Tabs.Tab>
				</Tabs>
			</Box>
		</Box>
	);
};
