import { useContext } from 'react';
import { LineOfBusinessCategory } from 'common.model/src/types/services/employer-service/LineOfBusinessCategory';

import { useEmployerId } from '../../../../hooks';
import { APIContext } from '../../../../../../../services/api/Api.context';

export const usePolicies = ({
	year,
	waitForYear,
}: {
	year?: string;
	waitForYear?: boolean;
}): { isLoading: boolean; count?: Record<LineOfBusinessCategory, number> } => {
	const employerId = useEmployerId();
	const {
		employerApi: { useGetPoliciesInsuranceProducts },
	} = useContext(APIContext);
	const { isLoading, data } = useGetPoliciesInsuranceProducts({ employerId, year }, { enabled: waitForYear ? !!year : true });

	return {
		isLoading,
		count: data,
	};
};
