import { useContext, useMemo } from 'react';
import { ChartColors } from '@benefitflow/designsystem/dist/components/Chart/Chart.types';
import { ChartBar } from '@benefitflow/designsystem/dist/components/Chart/components/Bar/Bar.types';

import { useGetCompanyColor } from '../../../hooks';
import { useEmployerId } from '../../../../../../../../hooks';
import { useEmployerHistoryTabStore } from '../../../../../History.store';
import { APIContext } from '../../../../../../../../../../../services/api/Api.context';
import { FormattingUtils } from '../../../../../../../../../../../../ui-components';

export const useChartData = ({
	companyType,
	getCompanyColor,
}: {
	companyType: 'broker' | 'carrier';
	getCompanyColor: ReturnType<typeof useGetCompanyColor>;
}) => {
	const employerId = useEmployerId();
	const {
		policyCategorySelector: { values: categories },
	} = useEmployerHistoryTabStore();
	const {
		employerApi: { useGetPolicyHistoryChartData, useGetPolicyHistory },
	} = useContext(APIContext);
	const { data, isLoading } = useGetPolicyHistoryChartData(
		{
			categories,
			companyType,
			employerId,
		},
		{
			select: (data) => data.flatMap(({ year, ...data }) => ({ name: year, ...data })),
		},
	);
	const { data: policyHistory } = useGetPolicyHistory({
		companyType,
		employerId,
		perPage: 10,
		categories,
	});

	const bars = useMemo<ChartBar[]>(() => {
		const companies = policyHistory?.pages?.flatMap(({ data }) => data).map(({ company }) => company) ?? [];
		const mainCompanies = companies.slice(0, 4);
		const otherCompanies = companies.slice(4);
		const getTotalByYear = (() => {
			const valuesByYear = new Map<string, number>();
			data?.forEach((data) => {
				Object.entries(data).forEach(([key, value]) => {
					if (key === 'name') return;
					if (valuesByYear.get(data.name) === undefined) valuesByYear.set(data.name, 0);
					valuesByYear.set(data.name, (valuesByYear.get(data.name) ?? 0) + Number(value));
				});
			});

			return valuesByYear;
		})();

		return [
			...mainCompanies.map((company) => ({ name: company, color: getCompanyColor({ company, companyType }) })),
			...(otherCompanies.length
				? [
						{
							name: 'Other',
							color: ChartColors.ORANGE,
							formatValue: (value, year) => `$${FormattingUtils.formatNumberAbbrev(getTotalByYear.get(year))}`,
							showLabel: true,
						} satisfies ChartBar,
					]
				: []),
		];
	}, [data, policyHistory, getCompanyColor, companyType]);

	return { data, bars, isLoading };
};
