import {
	MutationOptions,
	QueryObserverOptions,
	useInfiniteQuery,
	UseInfiniteQueryOptions,
	useMutation,
	UseMutationResult,
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from '@tanstack/react-query';
import { APIClient } from './types';
import { PaginatedRequest } from 'common.model/src/types/Paginated';
import { PaginatedResponse } from 'common.model/src/types/Pagination';

export interface IApiResponse<T> {
	query: UseQueryResult<T, unknown>;
	data: T;
	loading: boolean;
}

export interface IMutationResponse<TRes> {
	mutate: any;
	loading: boolean;
}

export class ApiBase {
	constructor(
		readonly client: APIClient,
		readonly loggedInUser: string,
		readonly servicePath: string,
	) {}

	private getFullPath = (queryKey: string) => `${this.servicePath}/${queryKey}`;

	async post<TReq, TRes>(queryKey: string, req: TReq): Promise<TRes> {
		const fullPath = this.getFullPath(queryKey);
		const response = await this.client.post<any, any>(fullPath, req);
		if (response.data.res.success) {
			return response.data.res.data;
		}
		throw new Error(response.data.res.message);
	}

	/**
	 * @deprecated
	 */
	callAuthenticated<TReq, TRes>(queryKey: string, req: TReq, options?: QueryObserverOptions<TRes>): IApiResponse<TRes> {
		const fullPath = this.getFullPath(queryKey);
		const query = useQuery<TRes>(
			[fullPath, req, this.loggedInUser], // req.toString() is used to prevent caching??
			async () => this.post<TReq, TRes>(queryKey, req),
			options,
		);
		return {
			query: query,
			data: query.data,
			loading: query.isLoading,
		};
	}

	/**
	 * @deprecated
	 * This doen't return the whole mutation object, only the mutate function and loading state.
	 * Also the params are beeing passed in the hook instantiation instead of the mutate function.
	 */
	mutateAuthenticated<TReq, TRes>(queryKey: string, req: TReq, options?: MutationOptions<TRes>): IMutationResponse<TRes> {
		const fullPath = this.getFullPath(queryKey);
		const { mutate, isLoading } = useMutation({
			mutationKey: [fullPath, req, this.loggedInUser],
			mutationFn: async () => this.post<TReq, TRes>(queryKey, req),
			...options,
		});
		return {
			mutate,
			loading: isLoading,
		};
	}

	mutate<TRes, TReq>(url: string, options) {
		const fullPath = this.getFullPath(url);
		const mutation = useMutation<TRes, unknown, TReq>({
			mutationKey: [fullPath],
			mutationFn: (data) => this.post(url, data),
			...options,
		});

		return mutation;
	}

	query<TRes, TReq, TParsed = TRes>(url: string, request: TReq, options: UseQueryOptions<TRes, unknown, TParsed>) {
		const fullPath = this.getFullPath(url);
		const query = useQuery<TRes, unknown, TParsed>([fullPath, request, this.loggedInUser], () => this.post(url, request), options);

		return query;
	}

	infiniteQuery<TRes, TReq, TParsed = TRes>(url: string, request: TReq, options: UseInfiniteQueryOptions<TRes, unknown, TParsed>) {
		const fullPath = this.getFullPath(url);
		const query = useInfiniteQuery<TRes, unknown, TParsed>(
			[fullPath, request, this.loggedInUser],
			(params) => this.post(url, { ...request, page: params.pageParam ?? 1 }),
			options,
		);

		return query;
	}
}
