import { useContext } from 'react';
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { GetServiceProviderFeesRequest, GetServiceProviderFeesResponse } from 'common.model/src/types/services/employer-service/EmployerService';

import { APIContext } from '../services/api/Api.context';
import { FormattingUtils } from '../../ui-components';

export type ServiceProviderDTO = Omit<GetServiceProviderFeesResponse, 'data'> & {
	data: {
		total: number;
		providers: {
			name: string;
			services: string[];
			value: { raw: number; formatted: string };
			providerId: string;
		}[];
	};
};

export const useServiceProviderFeesQuery = ({
	employerId,
	options,
	type,
	year,
}: { options?: UseInfiniteQueryOptions<GetServiceProviderFeesResponse, unknown, ServiceProviderDTO> } & Omit<GetServiceProviderFeesRequest, 'page'>) => {
	const {
		employerApi: { getServiceProviderFees },
	} = useContext(APIContext);
	const query = useInfiniteQuery<GetServiceProviderFeesResponse, unknown, ServiceProviderDTO>(
		['serviceProviderFees', employerId, year, type],
		async (params) => getServiceProviderFees({ employerId, year, type, page: params.pageParam ?? 1 }),
		{ ...options, select },
	);

	return query;
};

const select = (data: InfiniteData<GetServiceProviderFeesResponse>): InfiniteData<ServiceProviderDTO> => {
	return {
		...data,
		pages: data.pages.map((page) => ({
			...page,
			data: {
				total: page.data.total,
				providers: page.data.providers.map(({ name, services, value, providerId }) => ({
					name,
					services,
					providerId,
					value: {
						raw: value,
						formatted: `$${FormattingUtils.formatNumberAbbrev(value)}`,
					},
				})),
			},
		})),
	};
};
