import { Box, Chart as DSChart } from '@benefitflow/designsystem';

import { useChartData, useLegend } from './hooks';
import { useGetCompanyColor } from '../../hooks';
import { FormattingUtils } from '../../../../../../../../../../../ui-components';

export const Chart = ({
	companyType,
	getCompanyColor,
	title,
}: {
	title: string;
	companyType: 'broker' | 'carrier';
	getCompanyColor: ReturnType<typeof useGetCompanyColor>;
}) => {
	const { bars, data, isLoading } = useChartData({ companyType, getCompanyColor });
	const { legend, isLoading: isLegendLoading } = useLegend({ companyType, getCompanyColor });

	return (
		<Box display="flex" gap="lg">
			<Box style={{ width: '70%' }}>
				<DSChart.Bar
					isLoading={isLoading}
					formatYAxisLabel={(value) => `$${FormattingUtils.formatNumberAbbrev(value)}`}
					renderTooltip={(props) => (
						<DSChart.Tooltip
							title={props.label}
							values={
								props?.payload?.map(({ payload, dataKey }) => ({
									title: String(dataKey),
									color: dataKey && getCompanyColor({ company: String(dataKey), companyType }),
									value: dataKey ? `$${FormattingUtils.formatNumberAbbrev(payload[dataKey])}` : undefined,
								})) ?? []
							}
						/>
					)}
					bars={bars}
					data={data}
				/>
			</Box>
			<Box style={{ width: '30%' }}>
				<DSChart.Legend isLoading={isLegendLoading} height={260} data={legend} colorScheme="solid" title={title} />
			</Box>
		</Box>
	);
};
