import { create } from 'zustand';

import { EmployerProfileBenefitsTabStore } from './Benefits.types';
import { createPolicySelectorMethods } from '../../../../../../utilities/StoreUtils/createPolicySelectorMethods';

export const useEmployerProfileBenefitsTabStore = create<EmployerProfileBenefitsTabStore>((set, get) => ({
	year: {
		value: '',
		setValue: (value) =>
			set((state) => ({
				year: { ...state.year, value },
			})),
	},
	brokerColorMap: {
		setValues: (values) =>
			set((state) => ({
				brokerColorMap: { ...state.brokerColorMap, values },
			})),
		values: {},
	},
	carrierColorMap: {
		setValues: (values) =>
			set((state) => ({
				carrierColorMap: { ...state.carrierColorMap, values },
			})),
		values: {},
	},
	policyCategorySelector: createPolicySelectorMethods(set),
}));
