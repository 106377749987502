import { useEffect } from 'react';
import { LineOfBusinessCategory } from 'common.model/src/types/services/employer-service/LineOfBusinessCategory';

import { PolicySelectorStore } from '../../../../../../../utilities/StoreUtils/createPolicySelectorMethods';

export const useClearSelectionOnYearChange = ({ store, year }: { store: PolicySelectorStore; year: string }) => {
	const {
		policyCategorySelector: { setValues },
	} = store;

	useEffect(() => {
		setValues([LineOfBusinessCategory.ALL]);
	}, [setValues, year]);
};
