import { useState } from 'react';
import { Skeleton, Box } from '@benefitflow/designsystem';
import { SaveSearchDialog } from './components/SaveSearchDialog/SaveSearchDialog';
import { CrmObjectTypeEnum } from 'common.model/src/types/services/integration/fieldMapping/CrmObjectTypeEnum';
import { countFilters } from 'new-beginning/components/pages/Search/SavedSearchesButton/helpers/CountNumberOfFilters';

export const SaveSearchButton = ({ loading, filters, crmObjectTypeEnum }: { loading?: boolean; filters: any; crmObjectTypeEnum: CrmObjectTypeEnum }) => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	if (loading) return <Skeleton height="xl2" width="xl4" />;

	return (
		<>
			<Box width="full" mt="md">
				<button className="btn btn-primary" style={{ width: '100%' }} disabled={countFilters(filters) === 0} onClick={() => setIsDialogOpen(true)}>
					Save Search
				</button>
			</Box>

			<SaveSearchDialog
				filters={filters}
				crmObjectTypeEnum={crmObjectTypeEnum}
				isSaveSearchDialogOpen={isDialogOpen}
				setIsSaveSearchDialogOpen={setIsDialogOpen}
			/>
		</>
	);
};
