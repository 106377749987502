import { useContext, useMemo } from 'react';
import { APIContext } from '../../../../../../../../../services/api/Api.context';
import { useEmployerId } from '../../../../../../hooks';
import { FormattingUtils } from 'ui-components';

export const useKeyPartners = () => {
	const employerId = useEmployerId();
	const {
		employerApi: { useGetKeyPartners },
	} = useContext(APIContext);
	const { data, isLoading } = useGetKeyPartners({ employerId });

	const formatted = useMemo(() => {
		const formatted = data;
		if (data?.primaryBenefitsBroker && data?.primaryBenefitsBroker?.website) {
			formatted.primaryBenefitsBroker['logo'] = `https://logo.clearbit.com/${data.primaryBenefitsBroker.website}`;
		}
		if (data?.primaryBenefitsBroker?.name === 'NO Broker') {
			formatted.primaryBenefitsBroker = null;
		}
		if (data?.pncCarrier?.name) {
			formatted.pncCarrier = { name: FormattingUtils.formatUpperCasing(data?.pncCarrier?.name) };
		}
		if (data?.pncBroker?.name) {
			formatted.pncBroker = { name: FormattingUtils.formatUpperCasing(data?.pncBroker?.name) };
		}

		return formatted;
	}, [data]);

	return { data: formatted, isLoading };
};
