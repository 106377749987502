import { Card, Box } from '@benefitflow/designsystem';

import { Section } from './components';
import { useGetCompanyColor } from './hooks';
import { useEmployerHistoryTabStore } from '../../History.store';
import { PoliciesSelector } from '../../../PoliciesSelector/PoliciesSelector';

export const PolicyHistory = () => {
	const store = useEmployerHistoryTabStore();
	const getCompanyColor = useGetCompanyColor();

	return (
		<Card title="Policy History" defaultPadding={false}>
			<PoliciesSelector store={store} waitForYear={false} />
			<Box m="lg">
				<Section
					labels={{
						sectionTitle: 'Broker Commissions',
						chartTitle: 'BROKERS',
						tableTitle: 'Brokers',
					}}
					companyType="broker"
					getCompanyColor={getCompanyColor}
				/>
				<Section
					labels={{
						sectionTitle: 'Carrier Premiums',
						chartTitle: 'CARRIERS',
						tableTitle: 'Carriers',
					}}
					companyType="carrier"
					getCompanyColor={getCompanyColor}
				/>
			</Box>
		</Card>
	);
};
