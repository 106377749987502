import { LineOfBusinessCategory } from 'common.model/src/types/services/employer-service/LineOfBusinessCategory';

export interface PolicySelectorMethods {
	values: LineOfBusinessCategory[];
	setValues: (values: LineOfBusinessCategory[]) => void;
	toggleValue: (value: LineOfBusinessCategory) => void;
}

export interface PolicySelectorStore {
	policyCategorySelector: PolicySelectorMethods;
}

export const createPolicySelectorMethods = (set: (fn: (state: PolicySelectorStore) => Partial<PolicySelectorStore>) => void) => {
	return {
		values: [LineOfBusinessCategory.ALL],
		setValues: (values: LineOfBusinessCategory[]) =>
			set((state) => ({
				policyCategorySelector: {
					...state.policyCategorySelector,
					values,
				},
			})),
		toggleValue: (value) =>
			set((state) => {
				if (value === LineOfBusinessCategory.ALL) {
					if (!state.policyCategorySelector.values.includes(LineOfBusinessCategory.ALL))
						return {
							policyCategorySelector: {
								...state.policyCategorySelector,
								values: [LineOfBusinessCategory.ALL],
							},
						};
					return {
						policyCategorySelector: state.policyCategorySelector,
					};
				}
				if (state.policyCategorySelector.values.includes(LineOfBusinessCategory.ALL)) {
					return {
						policyCategorySelector: {
							...state.policyCategorySelector,
							values: [value],
						},
					};
				}
				if (state.policyCategorySelector.values.includes(value)) {
					if (state.policyCategorySelector.values.length === 1)
						return {
							policyCategorySelector: {
								...state.policyCategorySelector,
								values: [LineOfBusinessCategory.ALL],
							},
						};
					return {
						policyCategorySelector: {
							...state.policyCategorySelector,
							values: state.policyCategorySelector.values.filter((policy) => policy !== value),
						},
					};
				}
				return {
					policyCategorySelector: {
						...state.policyCategorySelector,
						values: [...state.policyCategorySelector.values, value],
					},
				};
			}),
	};
};
