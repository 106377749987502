import classnames from 'classnames';
import { TenantUsage } from '../../../hooks/useTenantTeamInfo';
import { Box, Button, Icon, Chart } from '@benefitflow/designsystem';
import { ChartColors } from '@benefitflow/designsystem/dist/components/Chart/Chart.types';
import { bottom } from '@popperjs/core';

interface TeamHeaderProps {
	tenantTeam?: TenantUsage;
	showAddUser: () => void;
}

export const TeamHeader = ({ tenantTeam, showAddUser }: TeamHeaderProps) => {
	const { tenant, totalUsers } = tenantTeam || {};
	const numUsers = totalUsers || 0;
	const numSeats = tenant?.num_licenses || 0;
	const remainingSeats = numSeats - numUsers;
	return (
		<div className={classnames('header', 'admin-header', 'd-flex', 'flex-row', 'justify-content-between', 'align-items-center', 'flex-wrap')}>
			<div>
				<p className={classnames('h2', 'text-muted', 'm-0')}>My Team</p>
			</div>
			<div className={classnames('credit-usage-widget', 'd-flex', 'align-items-center', 'border', 'rounded', 'bg-white')}>
				<div className={classnames('d-flex', 'flex-column', 'justify-content-around', 'equal-child')}>
					<Box display="flex" flexDirection="row" justifyContent="between" pb="xxs">
						<div>
							<span>Credits</span>
						</div>
						<div>
							<span style={{ color: '#6c757d' }}>
								{tenant?.credits_used || 0}/{tenant?.credits || 0}
							</span>
						</div>
					</Box>
					<Box>
						<Chart.Fill
							height="sm"
							total={tenant?.credits || 0}
							parts={[
								{
									value: tenant?.credits_used || 0,
									data: {},
									color: ChartColors.LIGHT_BLUE,
								},
							]}
							fill
						/>
					</Box>
				</div>
				<div className={classnames('border', 'border-darker', 'border-left', 'h-100')} />
				<div className={classnames('user-section')}>
					<div className={classnames('d-flex', 'flex-column', 'justify-content-even', 'text-secondary', 'text-nowrap')}>
						<div>{`${numUsers} Active ${numUsers > 1 ? 'Users' : 'User'}`}</div>
						<div className={classnames({ 'text-secondary': remainingSeats === 0, 'text-success': remainingSeats > 0, 'text-danger': remainingSeats < 0 })}>
							{numSeats - numUsers} Open Licenses
						</div>
					</div>
					<div>
						<Button onClick={showAddUser} disabled={remainingSeats <= 0} icon="add">
							Add User
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};
