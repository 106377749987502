import { useNavigate } from 'react-router-dom';
import { InfinityList } from '@benefitflow/designsystem';

import { EmployerCard } from '../../../EmployerCard/EmployerCard';
import { useHeight } from '../../../EmployerCard/hooks/useHeight';
import { useScreenSize } from '../../../../../../hooks/useScreenSize';
import { EmployerDTO } from '../../../../../../queries/useEmployersQuery';

export const List = ({
	minHeight,
	rows,
	fetchNextPage,
	hasNextPage,
	isFetching,
	isLoading,
}: {
	hasNextPage: boolean;
	minHeight: number;
	rows: EmployerDTO[];
	fetchNextPage: () => void;
	isFetching: boolean;
	isLoading: boolean;
}) => {
	const screenSize = useScreenSize();
	const rowHeight = useHeight();
	const navigate = useNavigate();

	return (
		<InfinityList
			fetchNextPage={fetchNextPage}
			getLoaderRow={(index) => <EmployerCard key={index} loading />}
			getRow={({ data }) => (
				<EmployerCard
					key={data.id}
					{...data}
					loading={isLoading}
					moreInfoCallback={() => navigate(`/Employer/${data.id}`)}
					onClickBrokerLocation={() => {
						if (!data.brokerId) return;
						if (!data.brokerLocation || !data.id) {
							navigate(`/broker?brokerId=${data.brokerId}`);
							return;
						}
						navigate(`/broker?brokerId=${data.brokerId}&brokerLocation=${data.brokerLocation}&employer=${data.id}`);
					}}
				/>
			)}
			getRowHeight={() => rowHeight}
			hasNextPage={hasNextPage}
			height={'calc(100vh - 22rem)'}
			isFetching={isFetching}
			isLoading={isLoading}
			rows={rows}
			gap={12}
			minHeight={minHeight}
			screenSize={screenSize}
		/>
	);
};
