import { useMemo } from 'react';

import { useEmployerId } from '../../../../../../hooks';
import { useEmployerProfileBenefitsTabStore } from '../../../Benefits.store';
import { useFormattedPoliciesQuery } from '../../../../../../../../../queries';

export interface Policy {
	benefit: string;
	lives: string;
	renewal: string;
	brokerCommissions?: FillChartData;
	carrierPremiums?: FillChartData;
	pepm: string;
}

export interface FillChartData {
	total: {
		formatted: string;
		raw: number;
	};
	parts: {
		name: string;
		value: {
			formatted: string;
			raw: number;
		};
		companyId: string;
	}[];
}

export const usePolicies = ({
	type,
}: {
	type: 'medical' | 'ancillary';
}): {
	isLoading: boolean;
	totalCount: number;
	isFetching: boolean;
	hasNextPage: boolean;
	fetchNextPage: () => void;
	policies: Policy[];
	highestValues: { brokerCommission: number; carrierPremium: number };
} => {
	const employerId = useEmployerId();
	const { year, policyCategorySelector } = useEmployerProfileBenefitsTabStore();
	const { data, isLoading, hasNextPage, fetchNextPage, isFetching } = useFormattedPoliciesQuery({
		employerId,
		options: {
			getNextPageParam: (lastPage) => lastPage?.next,
			enabled: !!year.value,
		},
		type,
		year: year.value,
		insuranceProducts: policyCategorySelector.values,
	});
	const policies = useMemo(() => data?.pages?.flatMap((page) => page.policies) ?? [], [data]);

	return {
		highestValues: data?.pages?.[0]?.highestValues,
		totalCount: data?.pages?.[0]?.total ?? 0,
		policies,
		isLoading,
		isFetching,
		hasNextPage,
		fetchNextPage,
	};
};
