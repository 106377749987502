import { ApiBase, IApiResponse } from '../ApiBase';
import { APIClient } from '../types';
import { DeleteSavedSearchRequest } from 'common.model/src/types/services/saved-search-service/SavedSearchService';
import { SavedSearchAttributes } from 'common.model/src/db/models/SavedSearch';
import { QueryObserverOptions, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

export interface ISavedSearchApi {
	useGetSavedSearches: (options?: QueryObserverOptions<SavedSearchAttributes[]>) => IApiResponse<SavedSearchAttributes[]>;
	useCreate: (options?: UseMutationOptions<SavedSearchAttributes, unknown, SavedSearchAttributes>) => UseMutationResult<SavedSearchAttributes>;
	useUpdate: (options?: UseMutationOptions<SavedSearchAttributes, unknown, SavedSearchAttributes>) => UseMutationResult<SavedSearchAttributes>;
	useDelete: (options?: UseMutationOptions<boolean, unknown, DeleteSavedSearchRequest>) => UseMutationResult<boolean>;
}

export class SavedSearchApi extends ApiBase implements ISavedSearchApi {
	constructor(
		readonly client: APIClient,
		loggedInUser: string,
	) {
		super(client, loggedInUser, 'saved-search');
	}

	useGetSavedSearches = (options?: QueryObserverOptions<SavedSearchAttributes[]>): IApiResponse<SavedSearchAttributes[]> =>
		this.callAuthenticated('getSavedSearches', {}, options);

	useCreate = (options?: UseMutationOptions<SavedSearchAttributes, unknown, SavedSearchAttributes>): UseMutationResult<SavedSearchAttributes> =>
		this.mutate('create', options);

	useUpdate = (options?: UseMutationOptions<SavedSearchAttributes, unknown, SavedSearchAttributes>): UseMutationResult<SavedSearchAttributes> =>
		this.mutate('update', options);

	useDelete = (options?: UseMutationOptions<boolean, unknown, DeleteSavedSearchRequest>): UseMutationResult<boolean> =>
		this.mutate('deleteSavedSearch', options);
}
