import { Dispatch, ReactNode, SetStateAction } from 'react';
import { Box, Button, Skeleton, Typography } from '@benefitflow/designsystem';

import { useMode } from './hooks';

export const ToggleDetailsButton = ({
	isVisible,
	setVisibility,
	fetchNextPage,
	hasNextPage,
	total,
	isFetching,
}: {
	isVisible: boolean;
	setVisibility: Dispatch<SetStateAction<boolean>>;
	hasNextPage: boolean;
	fetchNextPage: () => void;
	total: number;
	isFetching: boolean;
}) => {
	const Container = ({ children }: { children: ReactNode }) => (
		<Box mb="lg" display="flex" justifyContent="center" alignItems="center">
			{children}
		</Box>
	);
	const mode = useMode({ isVisible, hasNextPage, total });
	console.log({ mode, hasNextPage });
	if (isFetching)
		return (
			<Container>
				<Skeleton width="xl2" height="md" />
			</Container>
		);
	if (mode === 'show-details')
		return (
			<Container>
				<Button variant="outlined" onClick={() => setVisibility(true)}>
					<Typography.Text color="primary-500">Show Details</Typography.Text>
				</Button>
			</Container>
		);
	if (mode === 'load-more')
		return (
			<Container>
				<Button variant="outlined" onClick={fetchNextPage}>
					<Typography.Text color="primary-500">Load More</Typography.Text>
				</Button>
			</Container>
		);
	if (mode === 'hide-details')
		return (
			<Container>
				<Button variant="outlined" onClick={() => setVisibility(false)}>
					<Typography.Text color="primary-500">Hide Details</Typography.Text>
				</Button>
			</Container>
		);
	return null;
};
