import { Box } from '@benefitflow/designsystem';

import { EmployeeHistory, PolicyHistory } from './components';

export const History = () => {
	return (
		<Box display="flex" flexDirection="column" gap="lg">
			<EmployeeHistory />
			<PolicyHistory />
		</Box>
	);
};
