import { useCallback, useContext } from 'react';
import { ChartColors } from '@benefitflow/designsystem/dist/components/Chart/Chart.types';
import { LineOfBusinessCategory } from 'common.model/src/types/services/employer-service/LineOfBusinessCategory';

import { useEmployerId } from '../../../../../../hooks';
import { CHART_COLORS } from '../../../../../../EmployerProfile.types';
import { APIContext } from '../../../../../../../../../services/api/Api.context';

export const useGetCompanyColor = () => {
	const employerId = useEmployerId();
	const {
		employerApi: { useGetPolicyHistory },
	} = useContext(APIContext);
	const { data: brokerPolicies } = useGetPolicyHistory({
		employerId,
		perPage: 10,
		companyType: 'broker',
		categories: [LineOfBusinessCategory.ALL],
	});
	const { data: carrierPolicies } = useGetPolicyHistory({
		employerId,
		perPage: 10,
		companyType: 'carrier',
		categories: [LineOfBusinessCategory.ALL],
	});
	const getCompanyColor = useCallback(
		({ company, companyType }: { company: string; companyType: 'carrier' | 'broker' }) => {
			if (companyType === 'broker') {
				return (
					brokerPolicies?.pages
						?.flatMap(({ data }) => data)
						?.reduce(
							(acc, cur, index) => ({
								...acc,
								[cur.company]: CHART_COLORS.slice(0, 4)[index],
							}),
							{},
						)[company] ?? ChartColors.ORANGE
				);
			}
			if (companyType === 'carrier') {
				return (
					carrierPolicies?.pages
						?.flatMap(({ data }) => data)
						?.reduce(
							(acc, cur, index) => ({
								...acc,
								[cur.company]: CHART_COLORS.slice(0, 4)[index],
							}),
							{},
						)[company] ?? ChartColors.ORANGE
				);
			}
			console.error('Invalid company type');
			return ChartColors.GREY;
		},
		[brokerPolicies, carrierPolicies],
	);

	return getCompanyColor;
};
