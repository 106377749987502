import { ReactElement, useEffect, useRef, useCallback, useMemo } from 'react';
import { Table, Box, TextButton, Button, Icon } from '@benefitflow/designsystem';
import { TenantUser } from 'new-beginning/hooks/useTenantTeamInfo';
import { USER_TYPE_MAPPING } from 'new-beginning/constants/user';
import { FaUserEdit, FaTrashAlt } from 'react-icons/fa';
import { CellAligment } from '@benefitflow/designsystem/dist/components/Table/Table.types';

interface UserTableProps {
	tenantUsers?: TenantUser[];
	setUserToEdit: (id: TenantUser | null) => void;
	setUserToDelete: (user: TenantUser) => void;
	loading: boolean;
	isFetching?: boolean;
	loadMoreUsers: () => void;
	hasMoreUsers: boolean;
	totalUsers: number;
}

export const UserTable = ({
	tenantUsers = [],
	setUserToEdit,
	setUserToDelete,
	loading,
	isFetching,
	loadMoreUsers,
	hasMoreUsers,
	totalUsers,
}: UserTableProps): ReactElement => {
	const loadMoreTriggerRef = useRef<HTMLDivElement>(null);
	const isEmpty = useMemo(() => !loading && tenantUsers.length === 0, [loading, tenantUsers]);

	const handleIntersection = useCallback(
		(entries: IntersectionObserverEntry[]) => {
			const [entry] = entries;
			if (entry.isIntersecting && hasMoreUsers && !loading && !isFetching) {
				loadMoreUsers();
			}
		},
		[hasMoreUsers, loading, isFetching, loadMoreUsers],
	);

	useEffect(() => {
		const observer = new IntersectionObserver(handleIntersection, {
			root: null,
			rootMargin: '100px',
			threshold: 0,
		});

		if (loadMoreTriggerRef.current) {
			observer.observe(loadMoreTriggerRef.current);
		}

		return () => observer.disconnect();
	}, [handleIntersection]);

	const columns = useMemo(
		() => [
			{
				title: 'User',
				key: 'name' as keyof TenantUser,
				cellAlign: 'left' as CellAligment,
				headerAlign: 'left' as CellAligment,
				render: ({ name, email }: TenantUser) => (
					<Box display="flex" flexDirection="column">
						<span>{name}</span>
						<span style={{ color: '#6c757d' }}>{email}</span>
					</Box>
				),
			},
			{
				title: 'Role',
				key: 'user_type' as keyof TenantUser,
				cellAlign: 'left' as CellAligment,
				headerAlign: 'left' as CellAligment,
				render: ({ user_type }: TenantUser) => <Box>{USER_TYPE_MAPPING?.[user_type] || 'N/A'}</Box>,
			},
			{
				title: 'Credits Used',
				key: 'credits_used' as keyof TenantUser,
				cellAlign: 'left' as CellAligment,
				headerAlign: 'left' as CellAligment,
				render: ({ credits_used, allocation_amount }: TenantUser) => (
					<Box>
						<span style={{ color: allocation_amount !== null && credits_used > allocation_amount ? '#dc3545' : 'inherit' }}>{credits_used || 0}</span>
					</Box>
				),
			},
			{
				title: 'Credits Allocated',
				cellAlign: 'left' as CellAligment,
				headerAlign: 'left' as CellAligment,
				key: 'allocation_amount' as keyof TenantUser,
				render: ({ allocation_amount }: TenantUser) => <Box>{allocation_amount || 'No Limit'}</Box>,
			},
			{
				title: 'Actions',
				cellAlign: 'left' as CellAligment,
				headerAlign: 'right' as CellAligment,
				key: 'id' as keyof TenantUser,
				render: (user: TenantUser) => (
					<Box display="flex" justifyContent="end" gap="xs">
						<Button onClick={() => setUserToEdit(user)}>
							<Icon name="user" size="md" />
						</Button>
						<Button onClick={() => setUserToDelete(user)} disabled={totalUsers <= 1}>
							<Icon name="trash" size="md" />
						</Button>
					</Box>
				),
			},
		],
		[tenantUsers.length, setUserToEdit, setUserToDelete],
	);

	const tableProps = Table.use<TenantUser>({
		columns: columns ?? [],
		data: tenantUsers,
		loading,
		isEmpty,
		empty: <Table.Empty style={{ height: '16rem' }} icon="duotone-building-magnifying-glass" label="No Active Users Found" />,
		skeleton: () => (
			<Table.Skeleton
				numberOfRows={5}
				columns={[
					{ height: 'sm', width: 'xl3' },
					{ height: 'sm', width: 'md' },
					{ height: 'sm', width: 'md' },
					{ height: 'sm', width: 'md' },
					{ height: 'sm', width: 'md' },
				]}
			/>
		),
	});

	return (
		<Box display="flex" flexDirection="column" my="lg">
			<Box>
				<Table {...tableProps} />
			</Box>
			{hasMoreUsers && !loading && !isFetching && <div ref={loadMoreTriggerRef} style={{ height: '10px' }} />}
			{isFetching && (
				<Box height="xl" display="flex" justifyContent="center" alignItems="center" width="full" mt="sm">
					<span className="spinner-border spinner-border-sm" />
				</Box>
			)}
		</Box>
	);
};
