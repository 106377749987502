import { Box, Table as DSTable } from '@benefitflow/designsystem';

import { Chart } from '../Chart/Chart';
import { Table } from '../Table/Table';
import { useGetCompanyColor } from '../../hooks';
import { useIsEmpty } from './hooks/useIsEmpty';

export const Section = ({
	companyType,
	getCompanyColor,
	labels: { chartTitle, sectionTitle, tableTitle },
}: {
	labels: Record<'sectionTitle' | 'chartTitle' | 'tableTitle', string>;
	companyType: 'broker' | 'carrier';
	getCompanyColor: ReturnType<typeof useGetCompanyColor>;
}) => {
	const isEmpty = useIsEmpty(companyType);

	return (
		<Box mb="xl">
			<Box mb="xl">
				<DSTable.Title title={sectionTitle} />
			</Box>
			{isEmpty ? (
				<DSTable.Empty style={{ height: '16rem' }} showBorder icon="duotone-building-magnifying-glass" label="Data not reported in filings" />
			) : (
				<>
					<Chart title={chartTitle} companyType={companyType} getCompanyColor={getCompanyColor} />
					<Table title={tableTitle} companyType={companyType} getCompanyColor={getCompanyColor} />
				</>
			)}
		</Box>
	);
};
