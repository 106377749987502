import { useContext, useMemo } from 'react';

import { useEmployerId } from '../../../../../../hooks';
import { APIContext } from '../../../../../../../../../services/api/Api.context';

export const useIsEmpty = () => {
	const employerId = useEmployerId();
	const {
		employerApi: { useGetEmployeeHistory },
	} = useContext(APIContext);
	const { data, isLoading } = useGetEmployeeHistory({ employerId, perPage: 10 });
	const isEmpty = useMemo(() => !isLoading && !data?.pages?.flatMap(({ data }) => data)?.length, [data, isLoading]);

	return isEmpty;
};
