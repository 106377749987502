import { IconButton, Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import { IoEllipsisHorizontal } from 'react-icons/io5';
import cn from 'classnames';

export interface SavedSearchCardProps {
	searchType: string;
	numFilters: number;
	title: string;
	createdBy: string;
	createdTime: string;
	onDelete: () => void;
	onTitleClick: () => void;
}

const styles = {
	container: {
		width: '100%',
		height: '100%',
		padding: '12px',
		background: 'white',
		boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.04)',
		borderRadius: '4px',
		border: '0.5px solid #CBCDD1',
		display: 'inline-flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		gap: '10px',
	},
	content: {
		flex: '1 1 0',
		display: 'inline-flex',
		flexDirection: 'column' as const,
		justifyContent: 'center',
		alignItems: 'flex-start',
		gap: '8px',
	},
	badgeContainer: {
		display: 'inline-flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		gap: '8px',
	},
	searchTypeBadge: {
		padding: '4px 6px',
		background: '#F7FDEF',
		borderRadius: '2px',
		display: 'flex',
		gap: '4px',
	},
	searchTypeText: {
		color: '#334812',
		fontSize: '11px',
		fontFamily: 'Inter',
		fontWeight: 500,
		lineHeight: '12px',
	},
	filterBadge: {
		padding: '4px 6px',
		background: '#EDF2FC',
		borderRadius: '2px',
		display: 'flex',
		gap: '4px',
	},
	filterText: {
		color: '#0F3A8C',
		fontSize: '11px',
		fontFamily: 'Inter',
		fontWeight: 500,
		lineHeight: '12px',
	},
	title: {
		alignSelf: 'stretch',
		color: '#040404',
		fontSize: '14px',
		fontFamily: 'Inter',
		fontWeight: 500,
		lineHeight: '24px',
		cursor: 'pointer',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	createdInfo: {
		alignSelf: 'stretch',
	},
	createdByLabel: {
		color: '#3B3F47',
		fontSize: '12px',
		fontFamily: 'Inter',
		fontWeight: 400,
		lineHeight: '16px',
	},
	createdByName: {
		color: '#3B3F47',
		fontSize: '12px',
		fontFamily: 'Inter',
		fontWeight: 600,
		lineHeight: '16px',
	},
};

export const SavedSearchCard = ({ searchType, numFilters: filterCount, title, createdBy, createdTime, onDelete, onTitleClick }: SavedSearchCardProps) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleDelete = () => {
		handleMenuClose();
		onDelete?.();
	};

	return (
		<div style={styles.container}>
			<div style={styles.content}>
				<div style={styles.badgeContainer}>
					<div style={styles.searchTypeBadge}>
						<div style={styles.searchTypeText}>{searchType}</div>
					</div>
					<div style={styles.filterBadge}>
						<div style={styles.filterText}>{filterCount} Filters</div>
					</div>
				</div>
				<div
					style={styles.title}
					onClick={onTitleClick}
					onMouseEnter={(e) => (e.currentTarget.style.textDecoration = 'underline')}
					onMouseLeave={(e) => (e.currentTarget.style.textDecoration = 'none')}
				>
					{title}
				</div>
				<div style={styles.createdInfo}>
					<span style={styles.createdByLabel}>Created by </span>
					<span style={styles.createdByName}>{createdBy}</span>
					<span style={styles.createdByLabel}> {createdTime}</span>
				</div>
			</div>

			<IconButton className={cn('bf-icon', 'ic-action')} onClick={handleMenuOpen}>
				<IoEllipsisHorizontal />
			</IconButton>
			<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
				<MenuItem onClick={handleDelete}>Delete</MenuItem>
			</Menu>
		</div>
	);
};
