import { useCallback, useContext } from 'react';
import { ChartColors } from '@benefitflow/designsystem/dist/components/Chart/Chart.types';

import { useEmployerId } from '../../../../../../hooks';
import { CHART_COLORS } from '../../../../../../EmployerProfile.types';
import { APIContext } from '../../../../../../../../../services/api/Api.context';

export const useGetPlanColor = () => {
	const employerId = useEmployerId();
	const {
		employerApi: { useGetEmployeeHistory },
	} = useContext(APIContext);
	const { data } = useGetEmployeeHistory({ employerId, perPage: 10 });
	const getColor = useCallback<(plan: string) => ChartColors>(
		(plan: string) => {
			if (!data) return {};
			const map =
				data?.pages
					?.flatMap(({ data }) => data)
					?.reduce(
						(acc, { plan }, index) => ({
							...acc,
							[plan]: CHART_COLORS[index],
						}),
						{},
					) ?? {};
			return map[plan] || ChartColors.GREY;
		},
		[data],
	);

	return getColor;
};
