import { Box, Chart } from '@benefitflow/designsystem';
import { ChartColors } from '@benefitflow/designsystem/dist/components/Chart/Chart.types';

import { useGraphData } from './hooks';
import { FormattingUtils } from '../../../../../../../../../../../ui-components';

export const Graph = ({ getPlanColor }: { getPlanColor: (plan: string) => ChartColors }) => {
	const { data, lines, legend, isLoading } = useGraphData({ getPlanColor });

	return (
		<Box gap="lg" display="flex">
			<Box style={{ width: '70%' }}>
				<Chart.Line
					isLoading={isLoading}
					renderTooltip={(props) => (
						<Chart.Tooltip
							title={props.label}
							values={
								props?.payload
									?.sort((a, b) => {
										const aValue = a?.payload?.[a?.dataKey ?? ''] ?? 0;
										const bValue = b?.payload?.[b?.dataKey ?? ''] ?? 0;

										return bValue - aValue;
									})
									.map(({ payload, dataKey }) => {
										return {
											title: String(dataKey),
											color: dataKey && getPlanColor(String(dataKey)),
											value: dataKey ? String(FormattingUtils.formatNumberAbbrev(payload[dataKey])) : undefined,
										};
									}) ?? []
							}
						/>
					)}
					formatYAxisLabel={(value) => String(FormattingUtils.formatNumberAbbrev(value))}
					lines={lines}
					data={data}
				/>
			</Box>
			<Box style={{ width: '30%' }}>
				<Chart.Legend isLoading={isLoading} height={260} data={legend} colorScheme="solid" title="BENEFITS PLANS" />
			</Box>
		</Box>
	);
};
