import { Box, Paper, Dialog, Typography, Button, addNotification } from '@benefitflow/designsystem';
import { SavedSearchAttributes } from 'common.model/src/db/models';
import { CrmObjectTypeEnum, CrmObjectTypeUtils } from 'common.model/src/types/services/integration/fieldMapping/CrmObjectTypeEnum';
import { SavedSearchCard } from './components/SavedSearchCard/SavedSearchCard';
import { useBrokerContactsDetailsStore } from 'new-beginning/components/pages/BrokerProfile/components/Contacts/Contacts.store';
import { useBrokersStore } from '../../../Brokers/Brokers.store';
import { useServiceProvidersStore } from '../../../components/ServiceProviders/ServiceProviders.store';
import { useEmployerContactsStore } from '../../../EmployerContacts/EmployerContacts.store';
import { useEmployersStore } from '../../../Employers/Employers.store';
import { useSearchStore } from '../../../Search.store';
import { queryClient } from 'new-beginning/lib/queryClient';
import { Notifications } from 'new-beginning/constants/notifications';
import { countFilters } from '../../helpers/CountNumberOfFilters';
import { BrokerFilters, EmployerFilters, ProviderFilters } from 'common.model/src/types/common/search/filters/CompanyFilters';
import { BrokerContactFilters, EmployerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';
import { APIContext } from 'new-beginning/services/api/Api.context';
import { useContext } from 'react';
import { getDialogZIndex } from 'new-beginning/utilities/ListActionUtils/getDialogZIndex';
import { useBrokerContactsStore } from '../../../BrokerContacts/BrokerContacts.store';

export const SavedSearchesDialog = ({
	isSaveSearchDialogOpen,
	setIsSaveSearchDialogOpen,
}: {
	isSaveSearchDialogOpen: boolean;
	setIsSaveSearchDialogOpen: (isOpen: boolean) => void;
}) => {
	const { savedSearchApi } = useContext(APIContext);
	const { setAllFilters: setBrokerContactFilters } = useBrokerContactsStore();
	const { setAllFilters: setEmployerContactFilters } = useEmployerContactsStore();
	const { setAllFilters: setEmployerFilters } = useEmployersStore();
	const { setAllFilters: setProviderFilters } = useServiceProvidersStore();
	const { setAllFilters: setBrokerFilters } = useBrokersStore();
	const { tab, setTab } = useSearchStore();

	const { data: savedSearches, loading: savedSearchesLoading } = savedSearchApi.useGetSavedSearches({
		refetchOnMount: true,
		refetchOnWindowFocus: true,
	});

	const { mutate: deleteSavedSearch } = savedSearchApi.useDelete({
		onSuccess: (_, variables) => {
			queryClient.invalidateQueries({
				queryKey: ['saved-search/getSavedSearches'],
			});
			const notification = Notifications.SaveSearch.Delete({
				name: savedSearches?.filter((search) => search.id === variables.saved_search_id)?.[0]?.name,
			});
			addNotification(notification);
		},
		onError: () => {
			addNotification({
				type: 'error',
				title: 'Error deleting Saved Search.',
				message: 'Please try again later.',
			});
		},
	});

	const getDaysAgo = (date: Date) => {
		if (!date) return '';
		const now = new Date();
		// Set both dates to midnight for accurate day comparison
		const dateAtMidnight = new Date(date.getFullYear(), date.getMonth(), date.getDate());
		const nowAtMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate());

		if (dateAtMidnight.getTime() === nowAtMidnight.getTime()) return 'Today';

		const diffTime = Math.abs(now.getTime() - date.getTime());
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

		// Get a string to show
		switch (diffDays) {
			case 1:
				return 'Yesterday';
			default:
				return `${diffDays} days ago`;
		}
	};

	const onTitleClick = (search: SavedSearchAttributes) => {
		const filters = search.filters;

		// Map object types to their corresponding tab names
		const objectTypeToTab = {
			[CrmObjectTypeEnum.BROKER]: 'brokers',
			[CrmObjectTypeEnum.EMPLOYER]: 'employers',
			[CrmObjectTypeEnum.EMPLOYER_CONTACT]: 'employerContacts',
			[CrmObjectTypeEnum.SERVICE_PROVIDER]: 'serviceProviders',
			[CrmObjectTypeEnum.BROKER_CONTACT]: 'brokerContacts',
		};

		// Set tab if it doesn't match the current search type
		const targetTab = objectTypeToTab[search.object_type];
		if (!targetTab) {
			throw new Error(`Cant find search tab for object type: ${search.object_type}`);
		}
		if (tab !== targetTab) {
			setTab(targetTab);
		}

		// Apply filters based on object type
		switch (search.object_type) {
			case CrmObjectTypeEnum.BROKER:
				setBrokerFilters(filters as BrokerFilters);
				break;
			case CrmObjectTypeEnum.EMPLOYER:
				setEmployerFilters(filters as EmployerFilters);
				break;
			case CrmObjectTypeEnum.EMPLOYER_CONTACT:
				setEmployerContactFilters(filters as EmployerContactFilters);
				break;
			case CrmObjectTypeEnum.SERVICE_PROVIDER:
				setProviderFilters(filters as ProviderFilters);
				break;
			case CrmObjectTypeEnum.BROKER_CONTACT:
				setBrokerContactFilters(filters as BrokerContactFilters);
				break;
			default:
				throw new Error(`Cant set filters for object type: ${search.object_type}`);
		}

		setIsSaveSearchDialogOpen(false);
	};

	return (
		<Dialog zIndex={getDialogZIndex()} open={isSaveSearchDialogOpen}>
			<Box style={{ width: '35rem', height: '550px' }}>
				<Paper p="none" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
					<Box p="lg">
						<Box display="flex" flexDirection="column" gap="md">
							<Typography.Text size="lg" color="neutral-700">
								Saved Searches
							</Typography.Text>
							<Typography.Text size="md" color="neutral-500">
								Select one of your existing Saved Searches
							</Typography.Text>
							<Box
								style={{
									overflowY: 'auto',
									maxHeight: 'calc(550px - 200px)', // Accounts for header and footer space
								}}
								alignItems="center"
								gap="sm"
							>
								{savedSearches?.map((search) => (
									<Box key={search.id} display="flex" flexDirection="column" gap="md" mb="md">
										<SavedSearchCard
											searchType={CrmObjectTypeUtils.getName(search.object_type)}
											numFilters={countFilters(search.filters)}
											title={search.name}
											createdBy={search.created_by_user_name}
											createdTime={getDaysAgo(new Date(search.created_at))}
											onDelete={() => deleteSavedSearch({ saved_search_id: search.id })}
											onTitleClick={() => onTitleClick(search)}
										/>
									</Box>
								))}
							</Box>
						</Box>
					</Box>

					<Box
						m="lg"
						display="flex"
						justifyContent="end"
						alignItems="center"
						gap="sm"
						style={{
							marginTop: 'auto', // Pushes the footer to the bottom
						}}
					>
						<Box cursor="pointer" onClick={() => setIsSaveSearchDialogOpen(false)}>
							<Typography.Text color="primary-500">Cancel</Typography.Text>
						</Box>
					</Box>
				</Paper>
			</Box>
		</Dialog>
	);
};
