import { SaveableFilters } from 'common.model/src/db/models/SavedSearch';
import { IncludeExcludeField } from 'common.model/src/types/common/search/filters/filters';
import { Range } from 'common.model/src/types/common/search/filters/filters';

export const countFilters = (filters: SaveableFilters) => {
	if (!filters) return 0;

	let count = 0;
	const countedFilters: string[] = [];

	for (const key in filters) {
		const value = filters[key];
		if (!value) continue;

		switch (true) {
			// Handle IncludeExcludeField
			case value && typeof value === 'object' && ('include' in value || 'exclude' in value):
				const ieField = value as IncludeExcludeField<any>;
				if (ieField.include?.length > 0) {
					count++;
					countedFilters.push(`${key} (include)`);
				}
				if (ieField.exclude?.length > 0) {
					count++;
					countedFilters.push(`${key} (exclude)`);
				}
				break;

			// Handle Range objects
			case value && typeof value === 'object' && ('start' in value || 'end' in value):
				const range = value as Range;

				// Employee Counts
				if (key == 'participantRanges' && (range.start !== 0 || range.end !== 3000000)) {
					count++;
					countedFilters.push(`${key} (range)`);
				}
				// Renewal Dates
				else if ((key == 'employerRenewalDatesRange' || key == 'renewalDatesRanges') && (range.start !== 1 || range.end !== 12)) {
					count++;
					countedFilters.push(`${key} (range)`);
				}
				break;

			// Handle arrays
			case Array.isArray(value):
				if (value.length > 0) {
					if (key == 'yearsAtCompany') {
						if (value[0] != 0 || value[1] != 21) {
							count++;
							countedFilters.push(`${key} (array)`);
						}
					} else {
						count++;
						countedFilters.push(`${key} (array)`);
					}
				}
				break;

			// Handle strings
			case typeof value === 'string':
				if (key == 'groupBy' || key == 'contactType') {
					break;
				} else if (value.length > 0) {
					count++;
					countedFilters.push(`${key} (string)`);
				}
				break;

			// Handle booleans
			case typeof value === 'boolean':
				if (value === true) {
					count++;
					countedFilters.push(`${key} (boolean)`);
				}
				break;

			// Handle numbers (excluding page number)
			case typeof value === 'number':
				if (key !== 'page') {
					count++;
					countedFilters.push(`${key} (number)`);
				}
				break;
		}
	}

	// console.log('Counted filters:', countedFilters);
	return count;
};
