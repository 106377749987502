export enum QueryKey {
	Lists = 'lists',
	Employer = 'employer',
	IntegrationConnectionDetails = 'integration-connection-details',
	EmployeeSummary = 'employee-summary',
	EmployeeSentiment = 'employee-sentiment',
	BrokerContacts = 'broker-contacts',
	BrokerContactsFilters = 'broker-contact-filters',
	SavedSearches = 'saved-search',
}
