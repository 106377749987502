import { useState, useEffect, useContext } from 'react';
import { Box, Paper, Dialog, Typography, Button, Input, Select } from '@benefitflow/designsystem';
import { CrmObjectTypeEnum } from 'common.model/src/types/services/integration/fieldMapping/CrmObjectTypeEnum';
import { SavedSearchAttributes } from 'common.model/src/db/models';
import { queryClient } from 'new-beginning/lib/queryClient';
import { useAddNotification } from '@benefitflow/designsystem';
import { Notifications } from 'new-beginning/constants/notifications';
import { SaveableFilters } from 'common.model/src/db/models/SavedSearch';
import { APIContext } from 'new-beginning/services/api/Api.context';
import { getDialogZIndex } from 'new-beginning/utilities/ListActionUtils/getDialogZIndex';

export const SaveSearchDialog = ({
	isSaveSearchDialogOpen,
	setIsSaveSearchDialogOpen,
	filters,
	crmObjectTypeEnum,
}: {
	isSaveSearchDialogOpen: boolean;
	setIsSaveSearchDialogOpen: (isOpen: boolean) => void;
	filters: SaveableFilters;
	crmObjectTypeEnum: CrmObjectTypeEnum;
}) => {
	const { savedSearchApi } = useContext(APIContext);

	const [searchType, setSearchType] = useState<'create' | 'update'>('create');
	const [searchName, setSearchName] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const defaultSearchData = {
		id: null,
		name: '',
		description: '',
		object_type: crmObjectTypeEnum,
		is_deleted: false,
		filters: filters,
	};
	const [searchData, setSearchData] = useState<SavedSearchAttributes | null>(defaultSearchData);
	const addNotification = useAddNotification();

	useEffect(() => {
		setSearchData((prev) => ({
			...prev,
			filters: filters,
		}));
	}, [filters]);

	const onSuccess = () => {
		setIsSaveSearchDialogOpen(false);
		setSearchName('');
		setSearchData(defaultSearchData);
		queryClient.invalidateQueries({
			queryKey: ['saved-search/getSavedSearches'],
		});
		const notification = Notifications.SaveSearch.Success({
			name: searchData.name,
		});
		addNotification(notification);
	};

	const onError = (str: string) => {
		return () => {
			addNotification({
				type: 'error',
				title: `Error ${str} Saved Search.`,
				message: 'Please try again later.',
			});
		};
	};

	const { mutate: createSearch } = savedSearchApi.useCreate({
		onSuccess,
		onError: onError('creating'),
	});

	const { mutate: updateSearch } = savedSearchApi.useUpdate({
		onSuccess,
		onError: onError('updating'),
	});

	const { data: savedSearches, loading: savedSearchesLoading } = savedSearchApi.useGetSavedSearches({
		refetchOnMount: true,
		refetchOnWindowFocus: true,
	});

	const createOrUpdateSearch = async () => {
		try {
			setIsLoading(true);
			if (searchType === 'create') {
				await createSearch(searchData);
			} else {
				await updateSearch(searchData);
			}
		} catch (error) {
			console.error('Failed to save search:', error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Dialog zIndex={getDialogZIndex()} open={isSaveSearchDialogOpen}>
			<Box style={{ width: '25rem' }}>
				<Paper p="none">
					<Box p="lg">
						<Box display="flex" flexDirection="column" gap="md">
							<Typography.Text size="lg" color="neutral-700">
								Save Search
							</Typography.Text>
							<Box display="flex" alignItems="center" gap="sm">
								<input
									type="radio"
									name="searchType"
									value="create"
									checked={searchType === 'create'}
									onChange={(e) => setSearchType(e.target.value as 'create' | 'update')}
								/>
								<Typography.Text onClick={() => setSearchType('create')}>New Search</Typography.Text>
							</Box>
							<Box ml="xl">
								<Input
									placeholder="Enter search name"
									value={searchName}
									onChange={(e) => {
										setSearchName(e.target.value);
										setSearchData({
											...searchData,
											id: null,
											name: e.target.value,
										});
									}}
								/>
							</Box>

							<Box display="flex" alignItems="center" gap="sm">
								<input
									type="radio"
									name="searchType"
									value="update"
									checked={searchType === 'update'}
									onChange={(e) => {
										setSearchType(e.target.value as 'create' | 'update');
									}}
								/>
								<Typography.Text onClick={() => setSearchType('update')}>Overwrite Existing</Typography.Text>
							</Box>
							<Box ml="xl">
								<Select
									defaultValue=""
									value={searchData.id?.toString() ?? ''}
									onChange={(e) => {
										const search = savedSearches?.filter((x) => x.id == parseInt(e.target.value));
										setSearchData({
											...search?.[0],
											filters: filters,
											id: parseInt(e.target.value),
										});
									}}
								>
									<option value="" disabled>
										Select existing search
									</option>
									{savedSearches?.map((search) => (
										<option key={search.id} value={search.id}>
											{search.name}
										</option>
									))}
								</Select>
							</Box>
						</Box>
					</Box>

					<Box m="lg" display="flex" justifyContent="end" alignItems="center" gap="sm">
						<Box cursor="pointer" onClick={() => setIsSaveSearchDialogOpen(false)}>
							<Typography.Text color="primary-500">Cancel</Typography.Text>
						</Box>
						<Button
							fetching={isLoading}
							onClick={createOrUpdateSearch}
							disabled={(!searchData.name && searchType === 'create') || (!searchData.id && searchType === 'update')}
						>
							Confirm
						</Button>
					</Box>
				</Paper>
			</Box>
		</Dialog>
	);
};
