import { Breakdowns } from 'common.model/src/types/common/search/Breakdowns';

import { useOfficeLocations } from './hooks';
import { useBrokerContactsDetailsStore } from '../../Contacts.store';
import { LOCATION_INPUT_PLACEHOLDER } from '../../../../../../../../app/constants';
import { Filters as FiltersComponent } from '../../../../../../shared/Filters/Filters';
import { useYearsAtCompany } from '../../../../../Search/BrokerContacts/components/Filters/hooks';
import { SaveSearchButton } from 'new-beginning/components/shared/SidebarFilters/components/SaveSearchButton/SaveSearchButton';
import { CrmObjectTypeEnum } from 'common.model/src/types/services/integration/fieldMapping/CrmObjectTypeEnum';

export const Filters = ({ options, isLoading }: { options: Breakdowns; isLoading: boolean }) => {
	const officeLocations = useOfficeLocations();
	const yearsAtCompany = useYearsAtCompany();
	const { filters, setFilters, filtersVisibilityMap, toggleFilterVisibility, resetFiltersVisibility, clearFilters } = useBrokerContactsDetailsStore();

	return (
		<FiltersComponent
			onClear={() => {
				resetFiltersVisibility();
				clearFilters();
			}}
		>
			<FiltersComponent.Section title="Contact Attributes" />
			<FiltersComponent.LocationFilter
				title="Contact Location"
				icon="location"
				description="Contacts located in [California]"
				placeholder={LOCATION_INPUT_PLACEHOLDER}
				isLoading={officeLocations.isLoading}
				options={officeLocations.options}
				isOpen={filtersVisibilityMap.contactLocation}
				onSelect={({ type, value }) => setFilters?.contactLocation?.[type].add(value)}
				onDelete={({ type, value }) => setFilters?.contactLocation?.[type].remove({ searchValue: value })}
				onSearch={officeLocations.onSearch}
				setRadiusMiles={setFilters.contactLocation.setRadiusMiles}
				onToggle={toggleFilterVisibility.contactLocation}
				selectedOptions={filters.contactLocation}
				testId=""
			/>
			<FiltersComponent.IncludeExcludeFilter
				title="Role"
				description="Contacts that act as [producer]"
				placeholder="e.g. Producer"
				icon="users"
				isLoading={isLoading}
				isOpen={filtersVisibilityMap.roles}
				onDelete={({ type, value }) => setFilters.roles?.[type].remove(value)}
				onSelect={({ type, value }) => setFilters.roles?.[type].add(value)}
				onToggle={toggleFilterVisibility.roles}
				options={options?.roles}
				selectedOptions={filters.roles}
				testId=""
			/>
			<FiltersComponent.IncludeExcludeFilter
				title="Job Title"
				description="Contacts that have a job title of [consultant]"
				placeholder="e.g. Consultant"
				icon="briefcase"
				isLoading={isLoading}
				isOpen={filtersVisibilityMap.jobTitles}
				onDelete={({ type, value }) => setFilters.jobTitles?.[type].remove(value)}
				onSelect={({ type, value }) => setFilters.jobTitles?.[type].add(value)}
				onToggle={toggleFilterVisibility.jobTitles}
				options={options?.jobTitles}
				selectedOptions={filters.jobTitles}
				testId=""
			/>
			<FiltersComponent.RangeFilter
				title={'Years At Company'}
				description={'Contacts that have [<2] years at current company'}
				isLoading={false}
				testId=""
				defaultOption={{ start: '0', end: '21' }}
				onToggle={toggleFilterVisibility.yearsAtCompany}
				isOpen={filtersVisibilityMap.yearsAtCompany}
				options={yearsAtCompany.options}
				onSelect={setFilters.yearsAtCompany}
				selectedRange={{ start: filters.yearsAtCompany[0], end: filters.yearsAtCompany[1] }}
				icon="calendar"
			/>
			<FiltersComponent.IncludeExcludeFilter
				title="License Type"
				description="Contacts that are licensed to sell [Health] insurance"
				placeholder="e.g. Health"
				icon="shield-check"
				isLoading={isLoading}
				isOpen={filtersVisibilityMap.licenseTypes}
				onDelete={({ type, value }) => setFilters.licenseTypes?.[type].remove(value)}
				onSelect={({ type, value }) => setFilters.licenseTypes?.[type].add(value)}
				onToggle={toggleFilterVisibility.licenseTypes}
				options={options?.licenseTypes}
				selectedOptions={filters.licenseTypes}
				testId=""
			/>
			<FiltersComponent.StringSearchFilter
				title="Carrier Appointments"
				description="Contacts that are licensed to sell [Aetna] products"
				placeholder="e.g. Aetna"
				icon="business"
				isLoading={false}
				isOpen={filtersVisibilityMap.carrierAppointments}
				onToggle={toggleFilterVisibility.carrierAppointments}
				onDelete={setFilters.carrierAppointments.include.remove}
				onSelect={setFilters.carrierAppointments.include.add}
				testId=""
				value={filters?.carrierAppointments?.include?.[0]}
			/>
			<FiltersComponent.Section title="Broker Attributes" />
			<FiltersComponent.LocationFilter
				title="Office Location"
				icon="location"
				description="Office locations in [California]"
				placeholder={LOCATION_INPUT_PLACEHOLDER}
				isLoading={officeLocations.isLoading}
				options={officeLocations.options}
				isOpen={filtersVisibilityMap.brokerOfficeLocations}
				onSelect={({ type, value }) => setFilters?.brokerOfficeLocations?.[type].add(value)}
				onDelete={({ type, value }) => setFilters?.brokerOfficeLocations?.[type].remove({ searchValue: value })}
				onSearch={officeLocations.onSearch}
				setRadiusMiles={setFilters.brokerOfficeLocations.setRadiusMiles}
				onToggle={toggleFilterVisibility.brokerOfficeLocations}
				selectedOptions={filters.brokerOfficeLocations}
				testId=""
			/>
			<SaveSearchButton filters={filters} crmObjectTypeEnum={CrmObjectTypeEnum.BROKER_CONTACT} />
		</FiltersComponent>
	);
};
